import { RefreshIcon } from "@heroicons/react/outline"
import { IconButton } from "@material-ui/core"
import { useAppContext } from "context"
import { nanoid } from "nanoid"
import { useEffect, useState } from "react"
import classNames from "utils/classNames"
import { useChatEmitter } from "utils/Emits"
import groupifyChat from "utils/groupifyChat"
import { isInViewport } from "utils/viewPort"
import Input from "./Input"
import View from "./View"

const ChatView = () => {
	const {
		chats,
		classCode,
		chatRoomId,
		user,
		socketState,
		unread,
		setValue,
	} = useAppContext()
	const { joinChatSocket, sendMessage } = useChatEmitter()
	const [msgToReplyTo, setMsgToReplyTo] = useState(null)

	const cancelReply = () => {
		setMsgToReplyTo(null)
	}

	useEffect(() => {
		if (unread.chat && isInViewport("#chatEnd"))
			setValue(state => ({
				...state,
				unread: {
					...state.unread,
					chat: 0,
				},
			}))
		document.addEventListener("scroll", () => {
			if (unread.chat && isInViewport("#chatEnd"))
				setValue(state => ({
					...state,
					unread: {
						...state.unread,
						chat: 0,
					},
				}))
		})
		return () => {
			document.removeEventListener("scroll", () => {
				if (unread.chat && isInViewport("#chatEnd"))
					setValue(state => ({
						...state,
						unread: {
							...state.unread,
							chat: 0,
						},
					}))
			})
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	const handleSendMessage = msg => {
		const tempId = nanoid(16)
		const message = {
			message: msg,
			roomId: chatRoomId || classCode,
			replyTo: msgToReplyTo?._id || null,
			user,
			createdAt: new Date().toISOString(),
			tempId,
		}
		setMsgToReplyTo(null)
		sendMessage(message)
		setValue(state => ({
			...state,
			chats: groupifyChat(
				[
					{
						...message,
						isDeleted: false,
						replyTo: msgToReplyTo
							? { ...msgToReplyTo, isDeleted: false }
							: msgToReplyTo,
					},
				],
				state.chats.reverse()
			).reverse(),
		}))
	}

	return (
		<>
			<div className="relative h-full">
				{(socketState.connection.chatSocket === false ||
					socketState.error.chatSocket === true) && (
					<div className="absolute inset-0 w-full z-50 bg-transparent flex justify-center items-center">
						<IconButton onClick={joinChatSocket}>
							<RefreshIcon className="w-6 h-6 text-red-500 hover:animate-spin" />
						</IconButton>
					</div>
				)}
				<div
					className={classNames(
						socketState.connection.chatSocket === false ||
							socketState.error.chatSocket === true
							? "blur-[2px]"
							: "",
						"h-full"
					)}
				>
					<div className="flex flex-col w-full md:h-screen md:h-screen-ios rounded-md pt-0.5 h-full overflow-hidden">
						<View
							messages={chats}
							setMsgToReplyTo={setMsgToReplyTo}
						/>
						<Input
							handleSendMessage={handleSendMessage}
							msgToReplyTo={msgToReplyTo}
							cancelReply={cancelReply}
						/>
					</div>
				</div>
			</div>
		</>
	)
}

export default ChatView
