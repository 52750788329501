import { IconButton, Tooltip } from "@material-ui/core"
import ArrowBackIcon from "@material-ui/icons/ArrowBack"
import { closeClass } from "utils/closeClass"

const BackBtn = ({ className }) => {
	const handleBack = () => {
		closeClass("closeClass")
	}

	return (
		<>
			<div className={className}>
				<Tooltip
					placement="bottom"
					title="Go Back"
				>
					<IconButton
						className="text-inherit"
						onClick={handleBack}
					>
						<ArrowBackIcon />
					</IconButton>
				</Tooltip>
			</div>
		</>
	)
}

export default BackBtn
