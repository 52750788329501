import { useAppContext } from "context"

const usePasteBinEmitter = () => {
	const { socketPoints, programCode, classCode } = useAppContext()

	const joinPasteBinSocket = () => {
		socketPoints.pasteBinSocket.emit("join", {
			programCode,
			classCode,
		})
		console.debug('pasteBinSocket.emit("join")', { programCode, classCode })
	}

	const sendResource = value => {
		socketPoints.pasteBinSocket.emit("resource", value)
		console.debug("@pasteBinSocket.emit('resource'):", value)
	}

	const toggleVisibility = value => {
		socketPoints.pasteBinSocket.emit("visible", value)
		console.debug("@pasteBinSocket.emit('visible'):", value)
	}

	const addAgenda = data => {
		socketPoints.pasteBinSocket.emit("addAgenda", data)
		console.debug("@pasteBinSocket.emit('addAgenda'):", data)
	}

	const deleteAgenda = data => {
		socketPoints.pasteBinSocket.emit("deleteAgenda", data)
		console.debug("@pasteBinSocket.emit('deleteAgenda'):", data)
	}

	const updateAgenda = data => {
		socketPoints.pasteBinSocket.emit("updateAgenda", data)
		console.debug("@pasteBinSocket.emit('updateAgenda'):", data)
	}

	return {
		joinPasteBinSocket,
		sendResource,
		toggleVisibility,
		addAgenda,
		deleteAgenda,
		updateAgenda,
	}
}

export default usePasteBinEmitter
