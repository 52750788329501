import { CalendarIcon } from "@heroicons/react/outline"
import { useAppContext } from "context"
import React, { useEffect } from "react"
import { useHistory, useLocation } from "react-router-dom"
import axios from "utils/axios"
import { closeClass } from "utils/closeClass"
import formatDate from "utils/dateFormater"
import {
	useBoringMeterEmitter,
	useChatEmitter,
	useMeetingEmitter,
	usePasteBinEmitter,
	useSessionEmitter,
} from "utils/Emits"
import { version } from "../../package.json"

const Join = () => {
	const {
		isPast,
		provider,
		meetingId,
		classTitle,
		classDate,
		participants,
		userInteracted,
		socketState,
		socketPoints,
		setValue,
	} = useAppContext()
	const history = useHistory()
	const { search } = useLocation()
	const { joinMeetingSocket } = useMeetingEmitter()
	const { joinChatSocket } = useChatEmitter()
	const { joinBoringMeterSocket } = useBoringMeterEmitter()
	const { joinPasteBinSocket } = usePasteBinEmitter()
	const { joinSessionSocket } = useSessionEmitter()
	const sockets = {
		meetingSocket: {
			socket: socketPoints.meetingSocket,
			join: joinMeetingSocket,
		},
		chatSocket: { socket: socketPoints.chatSocket, join: joinChatSocket },
		boringMeterSocket: {
			socket: socketPoints.boringMeterSocket,
			join: joinBoringMeterSocket,
		},
		pasteBinSocket: {
			socket: socketPoints.pasteBinSocket,
			join: joinPasteBinSocket,
		},
		sessionSocket: {
			socket: socketPoints.sessionSocket,
			join: joinSessionSocket,
		},
	}

	const handleInteraction = async join => {
		// Object.keys(sockets).forEach((socket) => sockets[socket].join());
		const socketPending = Object.keys(socketState.connection).filter(
			socket => socketState.connection[socket] === false
		)

		if (socketPending.length) {
			socketPending.every(socket => {
				console.debug(
					`${socket} connection status: ${sockets[socket].socket.connected}`
				)
				if (!sockets[socket].socket.connected) {
					closeClass(`${socket}Error`)
					return false
				}
				sockets[socket].join()
				return true
			})
		}
		if (!join) {
			setValue(prev => ({
				...prev,
				userInteracted: true,
				isPast: !join,
			}))

			history.push(`/${search}`)
			return
		}

		axios
			.post(`/v2.5/lms/classes/dyte/meeting/${meetingId}/add?role=HOST`)
			.then(res => {
				setValue(prev => ({
					...prev,
					userInteracted: true,
					isPast: !join ? !join : false,
					authToken: res.data.results.data.authToken,
				}))
				history.push(`/${search}`)
			})
			.catch(e => {
				console.error(e)
			})
	}

	useEffect(() => {
		if (userInteracted) closeClass("endClass")
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	return !userInteracted ? (
		<div className="h-screen w-screen flex flex-col justify-center items-center bg-gray-100 bg-graphics relative">
			<div className="max-w-4xl mx-2 md:mx-0 w-full max-h-[630px] flex items-center flex-col space-y-4">
				<div className="flex w-full md:w-1/2 mx-4 md:mx-0 bg-white rounded-md shadow p-6">
					<div className="w-full flex flex-col space-y-2">
						<div className="text-2xl font-medium flex-grow text-gray-900 line-clamp-2">
							{classTitle}
						</div>
						<div className="flex space-x-5">
							{Boolean(participants.length) && (
								<div className="flex items-center space-x-2 text-gray-500">
									<div className="flex -space-x-1 overflow-hidden items-center">
										{participants.slice(0, 2).map(p => (
											<img
												key={p.uid}
												className="inline-block h-6 w-6 rounded-full ring-2 ring-white"
												src={
													p?.profileImageUrl
														? p?.profileImageUrl
														: `https://avatars.dicebear.com/api/initials/${encodeURIComponent(
																p?.name
														  )}.svg`
												}
												alt=""
											/>
										))}
									</div>
									{participants.length > 2 ? (
										<span className="!ml-1 text-xs text-gray-500">
											+
											<span>
												{participants.length - 2} more
											</span>
										</span>
									) : null}
								</div>
							)}
							<div className="flex items-center space-x-2 text-gray-500">
								<CalendarIcon className="h-4 w-4" />
								<span className="text-sm text-gray-500">
									{formatDate(classDate).primary}
								</span>
							</div>
						</div>
					</div>
				</div>
				<div className="flex flex-col w-full md:w-1/2 mx-4 md:mx-0 bg-white rounded-md shadow">
					<div className="p-6 space-y-4">
						<div className="flex space-x-2">
							{isPast && (
								<button
									type="button"
									className="w-full text-center px-6 py-3 shadow border border-transparent text-base font-medium rounded-md text-gray-100 bg-gray-900 focus:outline-none focus:ring-0"
									onClick={() => handleInteraction(false)}
								>
									View Recording
								</button>
							)}
							{provider === "dyte" && (
								<button
									type="button"
									className="w-full text-center px-6 py-3 shadow border border-transparent text-base font-medium rounded-md text-gray-100 bg-gray-900 focus:outline-none focus:ring-0"
									onClick={() =>
										isPast
											? setValue(state => ({
													...state,
													isPast: !isPast,
											  }))
											: handleInteraction(!isPast)
									}
								>
									Start Class
								</button>
							)}
						</div>
					</div>
				</div>
			</div>
			<div className="space-x-1 md:absolute md:bottom-5 p-4 flex text-gray-400 text-xs">
				<span>Powered by</span>
				<img
					src="/assets/img/lu_icon.png"
					alt=""
					className="h-5 rounded-full square inline-block"
				/>
				<span className="text-purple-700 font-semibold leading-normal">
					LisA
				</span>
				<span className="h-auto px-1 py-0.5 rounded text-[8px] font-medium bg-pink-100 text-pink-800">
					Beta
				</span>
				<span className="leading-normal">v{version}</span>
			</div>
		</div>
	) : (
		<div className="h-screen w-screen bg-white"></div>
	)
}

export default Join
