import { useAppContext } from "context"
import { useEffect } from "react"
import axios from "utils/axios"
import { closeClass } from "utils/closeClass"
import CookieService from "utils/cookie.service"
import { useMeetingEmitter } from "utils/Emits"
import groupifyChat from "utils/groupifyChat"

const MeetingEvents = () => {
	const { classCode, socketPoints, user, setValue } = useAppContext()
	const { joinMeetingSocket } = useMeetingEmitter()

	const meetingSocketConnect = () => {
		// joinMeetingSocket();
		console.debug("@meetingSocket Connected via default")
	}

	// const meetingSocketConnected = () => {
	// 	console.debug("@meetingSocket Connected via custom");
	// 	joinMeetingSocket();
	// });

	const meetingSocketIoReconnect = attempt => {
		console.debug("@meetingSocket reconnect", attempt)
		joinMeetingSocket()
	}

	const meetingSocketIoReconnectAttempt = attempt => {
		console.debug("@meetingSocket reconnect_attempt", attempt)
	}
	const meetingSocketIoReconnectError = error => {
		console.debug("@meetingSocket reconnect_error", error.message)
	}
	const meetingSocketIoReconnectFailed = () => {
		console.debug("@meetingSocket reconnect_failed")
		setValue(state => ({
			...state,
			socketError: true,
			socketState: {
				...state.socketState,
				connection: {
					...state.socketState.connection,
					meetingSocket: false,
				},
				error: { ...state.socketState.error, meetingSocket: false },
			},
		}))
		closeClass("meetingSocketError")
	}

	const meetingSocketDisconnect = reason => {
		console.debug("@meetingSocket Disconnected: ", reason)
		setValue(state => ({
			...state,
			socketState: {
				...state.socketState,
				connection: {
					...state.socketState.connection,
					meetingSocket: false,
				},
				error: { ...state.socketState.error, meetingSocket: false },
			},
			participants: state.participants.filter(p => p.uid !== user.uid),
			chats: state.participants.find(p => p.uid === user.uid)
				? [
						{
							type: "member",
							msg: `${
								state.participants.find(p => p.uid === user.uid)
									.name
							} Left`,
							createdAt: new Date().toISOString(),
						},
						...state.chats,
				  ]
				: state.chats,
		}))
	}

	const meetingSocketError = data => {
		console.error("@meetingSocket ERROR: ", data)
		setValue(state => ({
			...state,
			socketError: true,
			socketState: {
				...state.socketState,
				connection: {
					...state.socketState.connection,
					meetingSocket: false,
				},
				error: { ...state.socketState.error, meetingSocket: false },
			},
		}))
		closeClass("meetingSocketError")
	}

	const meetingSocketJoined = data => {
		console.debug("@meetingSocket Joined: ", data)
		setValue(state => {
			const me = {
				uid: state.user.uid,
				name: state.user.name,
				username: state.user.username,
				profileImageUrl: state.user.profileImageUrl,
				role: state.user.role,
				handRaiseStatus: null,
				lastDoubtId: null,
				permissions: { chat: { isBanned: false, time: 0 } },
			}

			return {
				...state,
				meetingRoomId: data.roomId,
				socketState: {
					...state.socketState,
					connection: {
						...state.socketState.connection,
						meetingSocket: true,
					},
					error: { ...state.socketState.error, meetingSocket: false },
				},
				participants: state.participants.find(p => p.uid === me.uid)
					? [...state.participants.filter(p => p.uid !== me.uid), me]
					: [...state.participants, me],
			}
		})
	}

	const meetingSocketNewMember = newUser => {
		console.debug("@meetingSocket.on('newMember'):", newUser)
		newUser = {
			...newUser,
			lastDoubtId: null,
		}

		if (JSON.parse(CookieService.getAll().notification)) {
			const notifier = new Audio("/assets/audio/ding.mp3")
			notifier.crossOrigin = "anonymous"

			notifier.addEventListener("canplaythrough", () => {
				notifier.play()
			})
		}
		setValue(state => ({
			...state,
			participants: state.participants.find(p => p.uid === newUser.uid)
				? [
						...state.participants.filter(
							p => p.uid !== newUser.uid
						),
						newUser,
				  ]
				: [...state.participants, newUser],
			chats: groupifyChat(
				[
					{
						type: "member",
						status: "Joined",
						msg: `${newUser.name}`,
						createdAt: new Date().toISOString(),
					},
				],
				state.chats
			),
		}))
	}

	const meetingSocketLive = ({ isLive, downstreamUrl }) => {
		console.debug("@meetingSocket.on('live'):", isLive)
		setValue(state => ({
			...state,
			isLive,
			wentLive: isLive,
			videoDetails: {
				link: downstreamUrl,
			},
			downstreamUrl,
		}))
	}

	const meetingSocketHandRaise = ({ _id = null, user, question }) => {
		console.debug("@meetingSocket.on('handRaise'):", {
			uid: user.uid,
			question,
		})
		setValue(state => {
			return {
				...state,
				participants: state.participants.map(p => {
					if (p.uid === user.uid) {
						p.handRaiseStatus = "raised"
						p.lastDoubtId = _id
					}
					return p
				}),
				doubts: [
					{
						_id,
						user: {
							...user,
							lastDoubtId: _id,
							handRaiseStatus: "raised",
						},
						question,
						answer: null,
						createdAt: new Date().toISOString(),
					},
					...state.doubts,
				],
			}
		})
		const doubtContainer = document.getElementById("doubtContainer")
		doubtContainer &&
			doubtContainer.scrollTo({
				top: doubtContainer.scrollHeight,
				behavior: "smooth",
			})
	}

	const meetingSocketAnswered = ({
		uid,
		_id,
		question,
		solution,
		status,
	}) => {
		console.debug("@meetingSocket.on('answered'):", {
			uid,
			_id,
			solution,
		})
		setValue(state => ({
			...state,
			participants: state.participants.map(p => {
				if (p.uid === uid) p.handRaiseStatus = null
				return p
			}),
			doubts: state.doubts.map(d => {
				if (d._id === _id) d.answer = solution
				return d
			}),
		}))
	}

	const meetingSocketHandDown = ({ uid }) => {
		console.debug("@meetingSocket.on('handDown'):", uid)
		setValue(state => ({
			...state,
			participants: state.participants.map(p => {
				if (p.uid === uid) p.handRaiseStatus = null
				return p
			}),
		}))
	}

	const meetingSocketJoinedMeeting = ({ uid, participantId }) => {
		console.debug("@meetingSocket.on('joinedMeeting'):", participantId)
		setValue(state => ({
			...state,
			participants: state.participants.map(p => {
				if (p.uid === uid) {
					p.participantId = participantId
					p.handRaiseStatus = "accepted"
				}
				return p
			}),
		}))
	}

	const meetingSocketJoinClass = ({ uid }) => {
		console.debug("@meetingSocket.on('joinClass'):", uid)
		setValue(state => ({
			...state,
			participants: state.participants.map(p => {
				if (p.uid === uid) {
					p.handRaiseStatus = null
				}
				return p
			}),
			user: {
				...state.user,
				handRaiseStatus:
					state.user.uid === uid
						? "pending"
						: state.user.handRaiseStatus,
				role: state.user.uid === uid ? "participant" : state.user.role,
			},
		}))
	}

	const meetingSocketautoAdmitState = ({ enabled }) => {
		console.debug("@meetingSocket.on('autoAdmitEnabled'):", enabled)
		setValue(state => ({
			...state,
			user: {
				...state.user,
				handRaiseStatus: "pending",
				role: "participant",
			},
			autoAdmit: enabled,
		}))
	}

	const meetingSocketAccepted = ({ uid }) => {
		console.debug("@meetingSocket.on('accepted'):", uid)
		setValue(state => ({
			...state,
			participants: state.participants.map(p => {
				if (p.uid === uid) {
					p.handRaiseStatus = null
				}
				return p
			}),
			user: {
				...state.user,
				handRaiseStatus:
					state.user.uid === uid
						? "pending"
						: state.user.handRaiseStatus,
				role: state.user.uid === uid ? "participant" : state.user.role,
			},
		}))
	}

	const meetingSocketRejected = ({ uid }) => {
		console.debug("@meetingSocket.on('rejected'):", uid)
		setValue(state => ({
			...state,
			participants: state.participants.map(p => {
				if (p.uid === uid) p.handRaiseStatus = null
				return p
			}),
			user: {
				...state.user,
				handRaiseStatus:
					state.user.uid === uid ? null : state.user.handRaiseStatus,
				role: state.user.uid === uid ? "student" : state.user.role,
			},
		}))
	}

	const meetingSocketKick = ({ participantId }) => {
		console.debug("@meetingSocket.on('kick'):", participantId)
		axios.get(`/v2/lms/classes/status/${classCode}`).then(resp => {
			setValue(state => ({
				...state,
				participants: state.participants.map(p => {
					if (p.participantId === participantId) {
						p.handRaiseStatus = null
						p.participantId = null
					}
					return p
				}),
				user: {
					...state.user,
					handRaiseStatus:
						state.user.participantId === participantId
							? null
							: state.user.handRaiseStatus,
					role:
						state.user.participantId === participantId
							? "student"
							: state.user.role,
					participantId: null,
				},
				...resp.data.results.data,
			}))
		})
	}

	const meetingSocketMic = ({ participantId }) => {
		console.debug("@meetingSocket.on('mic'):", participantId)
		setValue(state => ({
			...state,
			user: {
				...state.user,
				misc: {
					...state.user.misc,
					micReq: state.user.participantId === participantId,
				},
			},
		}))
	}

	const meetingSocketCamera = ({ participantId }) => {
		console.debug("@meetingSocket.on('camera'):", participantId)
		setValue(state => ({
			...state,
			user: {
				...state.user,
				misc: {
					...state.user.misc,
					camReq: state.user.participantId === participantId,
				},
			},
		}))
	}

	const meetingSocketLeave = ({ participantId }) => {
		console.debug("@meetingSocket.on('leave'):", participantId)
		setValue(state => ({
			...state,
			participants: state.participants.map(p => {
				if (p.participantId === participantId) {
					p.handRaiseStatus = null
					p.participantId = null
				}
				return p
			}),
		}))
	}

	const meetingSocketMemberLeft = ({ uid }) => {
		console.debug("@meetingSocket.on('memberLeft'):", { uid })
		setValue(state => ({
			...state,
			participants: state.participants.filter(p => p.uid !== uid),
			chats: state.participants.find(p => p.uid === uid)
				? groupifyChat(
						[
							{
								type: "member",
								status: "Left",
								msg: `${
									state.participants.find(p => p.uid === uid)
										.name
								}`,
								createdAt: new Date().toISOString(),
							},
						],
						state.chats
				  )
				: state.chats,
		}))
	}

	useEffect(() => {
		socketPoints.meetingSocket.on("connect", meetingSocketConnect)
		// socketPoints.meetingSocket.on("connected", meetingSocketConnected);
		socketPoints.meetingSocket.io.on("reconnect", meetingSocketIoReconnect)
		socketPoints.meetingSocket.io.on(
			"reconnect_attempt",
			meetingSocketIoReconnectAttempt
		)
		socketPoints.meetingSocket.io.on(
			"reconnect_error",
			meetingSocketIoReconnectError
		)
		socketPoints.meetingSocket.io.on(
			"reconnect_failed",
			meetingSocketIoReconnectFailed
		)
		socketPoints.meetingSocket.on("disconnect", meetingSocketDisconnect)
		socketPoints.meetingSocket.on("error", meetingSocketError)
		socketPoints.meetingSocket.on("joined", meetingSocketJoined)
		socketPoints.meetingSocket.on("newMember", meetingSocketNewMember)
		socketPoints.meetingSocket.on("live", meetingSocketLive)
		socketPoints.meetingSocket.on("handRaise", meetingSocketHandRaise)
		socketPoints.meetingSocket.on("answered", meetingSocketAnswered)
		socketPoints.meetingSocket.on("handDown", meetingSocketHandDown)
		socketPoints.meetingSocket.on(
			"joinedMeeting",
			meetingSocketJoinedMeeting
		)
		socketPoints.meetingSocket.on("joinClass", meetingSocketJoinClass)
		socketPoints.meetingSocket.on(
			"autoAdmitState",
			meetingSocketautoAdmitState
		)
		socketPoints.meetingSocket.on("accepted", meetingSocketAccepted)
		socketPoints.meetingSocket.on("rejected", meetingSocketRejected)
		socketPoints.meetingSocket.on("kick", meetingSocketKick)
		socketPoints.meetingSocket.on("mic", meetingSocketMic)
		socketPoints.meetingSocket.on("camera", meetingSocketCamera)
		socketPoints.meetingSocket.on("leave", meetingSocketLeave)
		socketPoints.meetingSocket.on("memberLeft", meetingSocketMemberLeft)

		return () => {
			socketPoints.meetingSocket.off("connect", meetingSocketConnect)
			// socketPoints.meetingSocket.off("connected", meetingSocketConnected);
			socketPoints.meetingSocket.io.off(
				"reconnect",
				meetingSocketIoReconnect
			)
			socketPoints.meetingSocket.io.off(
				"reconnect_attempt",
				meetingSocketIoReconnectAttempt
			)
			socketPoints.meetingSocket.io.off(
				"reconnect_error",
				meetingSocketIoReconnectError
			)
			socketPoints.meetingSocket.io.off(
				"reconnect_failed",
				meetingSocketIoReconnectFailed
			)
			socketPoints.meetingSocket.off(
				"disconnect",
				meetingSocketDisconnect
			)
			socketPoints.meetingSocket.off("error", meetingSocketError)
			socketPoints.meetingSocket.off("joined", meetingSocketJoined)
			socketPoints.meetingSocket.off("newMember", meetingSocketNewMember)
			socketPoints.meetingSocket.off("live", meetingSocketLive)
			socketPoints.meetingSocket.off("handRaise", meetingSocketHandRaise)
			socketPoints.meetingSocket.off("answered", meetingSocketAnswered)
			socketPoints.meetingSocket.off("handDown", meetingSocketHandDown)
			socketPoints.meetingSocket.off(
				"joinedMeeting",
				meetingSocketJoinedMeeting
			)
			socketPoints.meetingSocket.off("joinClass", meetingSocketJoinClass)
			socketPoints.meetingSocket.off(
				"autoAdmitState",
				meetingSocketautoAdmitState
			)
			socketPoints.meetingSocket.off("accepted", meetingSocketAccepted)
			socketPoints.meetingSocket.off("rejected", meetingSocketRejected)
			socketPoints.meetingSocket.off("kick", meetingSocketKick)
			socketPoints.meetingSocket.off("mic", meetingSocketMic)
			socketPoints.meetingSocket.off("camera", meetingSocketCamera)
			socketPoints.meetingSocket.off("leave", meetingSocketLeave)
			socketPoints.meetingSocket.off(
				"memberLeft",
				meetingSocketMemberLeft
			)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	return <></>
}

export default MeetingEvents
