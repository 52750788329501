import { Loader, MeetingView, TabsView } from "components"
import { useAppContext } from "context"
import { useEffect } from "react"
import { UAParser } from "ua-parser-js"
import classNames from "utils/classNames"
import useSessionEmitter from "utils/Emits/useSessionEmitter"
import useBreakpoints from "utils/useBreakPoints"
import Meeting from "./Meeting"

const Class = () => {
	const {
		programCode,
		classCode,
		classDate,
		isLive,
		isPast,
		sideBarOpen,
		trainer,
		user,
	} = useAppContext()
	const { isMD } = useBreakpoints()
	const { sendSession } = useSessionEmitter()
	const parser = new UAParser()
	const deviceParser = parser.getResult()

	useEffect(() => {
		if (
			new Date(classDate) <= new Date() &&
			isLive &&
			trainer.email === user.email
		) {
			console.debug("Live Class Logging Started")
			sendSession({
				programCode,
				classCode,
				timespent: 0,
				activity: "Live Stream Started",
				source: {
					device: `${deviceParser.device.vendor} ${deviceParser.device.model}`,
					platform: deviceParser.os?.name,
					url: window.location.host,
				},
			})
			const sessionLogger = setInterval(() => {
				sendSession({
					programCode,
					classCode,
					timespent: 30,
					activity: "Live Stream Active",
					source: {
						device: `${deviceParser.device.vendor} ${deviceParser.device.model}`,
						platform: deviceParser.os?.name,
						url: window.location.host,
					},
				})
			}, 30 * 1000)
			return () => clearInterval(sessionLogger)
		} else {
			console.debug("Live Class Logging Not Started", isPast)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isLive])

	return user.uid ? (
		<>
			<Meeting>
				<div className="flex sm:flex-row flex-col bg-white h-screen select-none fixed inset-0">
					<div
						className={classNames(
							"sm:w-0 w-full sm:flex-1 bg-white",
							isPast ? "sm:h-screen" : "h-screen"
						)}
					>
						<MeetingView />
					</div>
					{isMD && (
						<div
							className={classNames(
								"border-l-[1px]",
								sideBarOpen
									? "w-full lg:w-3/12 md:w-4/12"
									: "w-auto"
							)}
						>
							<TabsView />
						</div>
					)}
				</div>
			</Meeting>
		</>
	) : (
		<div className="h-screen w-screen flex items-center justify-center bg-white">
			<Loader />
		</div>
	)
}

export default Class
