import { useDyteSelector } from "@dytesdk/react-web-core"
import { Switch } from "@headlessui/react"
// import { Switch } from "@headlessui/react";
import {
	CheckIcon,
	// CheckIcon,
	DotsVerticalIcon,
	XIcon,
} from "@heroicons/react/solid"
import { IconButton, Menu, MenuItem } from "@material-ui/core"
import MicOffIcon from "@material-ui/icons/MicOffOutlined"
import MicIcon from "@material-ui/icons/MicOutlined"
import RemoveCircleOutlineIcon from "@material-ui/icons/RemoveCircleOutline"
import VideocamSolidIcon from "@material-ui/icons/Videocam"
import VideocamOffIcon from "@material-ui/icons/VideocamOffOutlined"
import VideocamIcon from "@material-ui/icons/VideocamOutlined"
import { useAppContext } from "context"
import { useState } from "react"
import { useMeetingEmitter } from "utils/Emits"
import classNames from "utils/classNames"

const ToggleLoader = () => (
	<svg
		fill="none"
		viewBox="0 0 24 24"
		className="w-3 h-3 animate-spin"
	>
		<circle
			className="opacity-25"
			cx="12"
			cy="12"
			r="10"
			stroke="#ff66005e"
			strokeWidth="4"
		/>
		<path
			fill="#ff6600"
			d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
		/>
	</svg>
)

export const MeetingParticipantImage = ({ participant }) => {
	const { user, activeSpeaker } = useAppContext()
	const isSelf = user.participantId === participant.participantId
	const dyteParticipant = useDyteSelector(m =>
		isSelf ? m.self : m.participants.joined.get(participant.participantId)
	)

	return (
		<>
			<div className="square relative flex justify-center items-center">
				{activeSpeaker === participant.participantId &&
					dyteParticipant?.audioEnabled && (
						<span className="absolute inset-0 -left-1 h-12 m-auto square z-0 rounded-2xl overflow-hidden bg-green-500 animate-pulse" />
					)}
				<img
					src={
						participant.profileImageUrl
							? participant.profileImageUrl
							: `https://avatars.dicebear.com/api/initials/${participant.name}.svg`
					}
					alt={participant.name}
					className="h-10 rounded-md square relative z-10"
				/>
				{dyteParticipant?.videoEnabled && (
					<span className="absolute z-10 -bottom-1 -right-1 h-[18px] w-[18px] flex justify-center items-center rounded-full square bg-white shadow">
						<VideocamSolidIcon className="text-red-500 !h-3" />
					</span>
				)}
			</div>
		</>
	)
}

export const MeetingParticipantControl = ({ participant }) => {
	const { user, classCode, meetingRoomId, setValue } = useAppContext()
	const { action, cameraToggle, kick, micToggle } = useMeetingEmitter()
	const [anchorEl, setAnchorEl] = useState(null)
	const isSelf = user.participantId === participant.participantId
	const dyteParticipant = useDyteSelector(m =>
		isSelf ? m.self : m.participants.joined.get(participant.participantId)
	)

	const handleClick = event => {
		setAnchorEl(event.currentTarget)
	}

	const handleClose = () => {
		setAnchorEl(null)
	}

	const handleKick = () => {
		kick({
			participantId: participant.participantId,
			roomId: meetingRoomId || classCode,
		})
		action({
			metaData: { participantId: participant.participantId },
			roomId: meetingRoomId || classCode,
			action: "kickedUser",
		})
		dyteParticipant?.kick()
		setValue(state => ({
			...state,
			participants: state.participants.map(p => {
				if (p.participantId === participant.participantId) {
					p.handRaiseStatus = null
					delete p.participantId
				}
				return p
			}),
		}))
	}

	const handleMic = async () => {
		if (dyteParticipant?.audioEnabled) {
			action({
				metaData: { participantId: participant.participantId },
				roomId: meetingRoomId || classCode,
				action: "askedToMute",
			})
			dyteParticipant?.disableAudio()
		} else {
			action({
				metaData: { participantId: participant.participantId },
				roomId: meetingRoomId || classCode,
				action: "askedToUnmute",
			})
			micToggle({
				participantId: participant.participantId,
				roomId: meetingRoomId || classCode,
			})
		}
	}

	const handleCam = async () => {
		if (dyteParticipant?.videoEnabled) {
			action({
				metaData: { participantId: participant.participantId },
				roomId: meetingRoomId || classCode,
				action: "askedToTurnOffCamera",
			})
			dyteParticipant?.disableVideo()
		} else {
			action({
				metaData: { participantId: participant.participantId },
				roomId: meetingRoomId || classCode,
				action: "askedToTurnOnCamera",
			})
			cameraToggle({
				participantId: participant.participantId,
				roomId: meetingRoomId || classCode,
			})
		}
	}

	// const handleRemove = (participantId) => {
	// 	remove({
	// 		participantId,
	// 		roomId: meetingRoomId || classCode,
	// 	});
	// 	setValue((state) => ({
	// 		...state,
	// 		participants: state.participants.map((p) => {
	// 			if (p.participantId === participantId) {
	// 				p.handRaiseStatus = null;
	// 				delete p.participantId;
	// 			}
	// 			return p;
	// 		}),
	// 	}));
	// };

	return (
		<>
			<IconButton onClick={e => handleClick(e)}>
				<DotsVerticalIcon
					className="w-4 h-4 opacity-50"
					aria-hidden="true"
				/>
			</IconButton>
			<Menu
				id="simple-menu"
				anchorEl={anchorEl}
				keepMounted
				open={Boolean(anchorEl)}
				onClose={handleClose}
				onClick={handleClose}
				className="!shadow"
				anchorOrigin={{
					vertical: "top",
					horizontal: "left",
				}}
				transformOrigin={{
					vertical: "top",
					horizontal: "right",
				}}
			>
				<MenuItem onClick={handleCam}>
					{dyteParticipant?.videoEnabled ? (
						<div className="flex items-center space-x-2">
							<VideocamIcon className="!h-5 rounded-md square !text-gray-500" />
							<span className="text-sm text-gray-500">
								Turn Off Camera
							</span>
						</div>
					) : (
						<div className="flex items-center space-x-2">
							<VideocamOffIcon className="!h-5 rounded-md square !text-gray-500" />
							<span className="text-sm text-gray-500">
								Turn On Camera
							</span>
						</div>
					)}
				</MenuItem>
				<MenuItem onClick={handleMic}>
					{dyteParticipant?.audioEnabled ? (
						<div className="flex items-center space-x-2">
							<MicIcon className="!h-5 rounded-md square !text-gray-500" />
							<span className="text-sm text-gray-500">
								Turn Off Mic
							</span>
						</div>
					) : (
						<div className="flex items-center space-x-2">
							<MicOffIcon className="!h-5 rounded-md square !text-gray-500" />
							<span className="text-sm text-gray-500">
								Turn On Mic
							</span>
						</div>
					)}
				</MenuItem>
				<MenuItem onClick={handleKick}>
					<div className="flex items-center space-x-2">
						<RemoveCircleOutlineIcon className="!h-5 rounded-md square !text-gray-500" />
						<span className="text-sm text-gray-500">
							Kick from Meeting
						</span>
					</div>
				</MenuItem>
				{/* <MenuItem
					onClick={() => handleRemove(participant.participantId)}
				>
					<div className='flex items-center space-x-2'>
						<ExitToAppIcon className='h-8 rounded-md square' />
						<span className='text-sm text-gray-900'>
							Remove from Class
						</span>
					</div>
				</MenuItem> */}
			</Menu>
		</>
	)
}

export const MeetingAutoAdmit = () => {
	const { autoAdmit, meetingRoomId, classCode, setValue } = useAppContext()
	const { autoAdmitState } = useMeetingEmitter()
	const [loading, setLoading] = useState(false)

	return (
		<div className="px-4 pb-3 border-b pt-0 flex justify-between items-center space-x-2">
			<span className="text-sm font-medium truncate text-gray-900">
				Auto Admit
			</span>
			<Switch
				checked={autoAdmit}
				onChange={() => {
					setLoading(true)
					setTimeout(() => {
						autoAdmitState({
							enabled: !autoAdmit,
							roomId: meetingRoomId || classCode,
						})
						setValue(prev => ({
							...prev,
							autoAdmit: !autoAdmit,
						}))
						setLoading(false)
					}, 250)
				}}
				className={classNames(
					autoAdmit ? "bg-green-600" : "bg-gray-200",
					"relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-0"
				)}
			>
				<span className="sr-only">Auto Admit Toggle</span>
				<span
					className={classNames(
						autoAdmit ? "translate-x-5" : "translate-x-0",
						"pointer-events-none relative inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200"
					)}
				>
					<span
						className={classNames(
							autoAdmit
								? "opacity-0 ease-out duration-100"
								: "opacity-100 ease-in duration-200",
							"absolute inset-0 h-full w-full flex items-center justify-center transition-opacity"
						)}
						aria-hidden="true"
					>
						{loading ? (
							<ToggleLoader />
						) : (
							<XIcon className="h-3 w-3 text-gray-400" />
						)}
					</span>
					<span
						className={classNames(
							autoAdmit
								? "opacity-100 ease-in duration-200"
								: "opacity-0 ease-out duration-100",
							"absolute inset-0 h-full w-full flex items-center justify-center transition-opacity"
						)}
						aria-hidden="true"
					>
						{loading ? (
							<ToggleLoader />
						) : (
							<CheckIcon className="h-3 w-3 text-green-600" />
						)}
					</span>
				</span>
			</Switch>
		</div>
	)
}
