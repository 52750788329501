import {
	DyteCameraToggle,
	DyteControlbarButton,
	DyteGrid,
	DyteLeaveButton,
	DyteMicToggle,
	DyteNotifications,
	DyteParticipantsAudio,
	DyteScreenShareToggle,
	DyteSettingsToggle,
	DyteSetupScreen,
	extendConfig,
} from "@dytesdk/react-ui-kit"
import { useDyteMeeting, useDyteSelector } from "@dytesdk/react-web-core"
import { Slider } from "@material-ui/core"
import { withStyles } from "@material-ui/styles"
import {
	ShowCamRequestModal,
	ShowConfirmStreamStateModal,
	ShowMeetingLeaveConfirmationModal,
	ShowMeetingSettingsModal,
	ShowMicRequestModal,
	ShowStartStreamCountDownModal,
	ShowWarningModal,
} from "components/Modals"
import { useAppContext } from "context"
import { useEffect, useState } from "react"
import axios from "utils/axios"
import { closeClass } from "utils/closeClass"
// import CookieService from "utils/cookie.service";
import { useMeetingEmitter } from "utils/Emits"
import useBreakpoints from "utils/useBreakPoints"
import useHotkeys from "utils/useHotkeys"

const BoringSlider = withStyles({
	root: {
		color: "#ff6600",
		height: 8,
	},
	thumb: {
		height: 24,
		width: 24,
		backgroundColor: "#fff",
		border: "2px solid white",
		marginTop: -8,
		marginLeft: -12,
		boxShadow:
			"0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05)",
		"&:focus, &:hover, &$active": {
			boxShadow:
				"0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05)",
		},
	},
	valueLabel: {
		left: "calc(-50% + 8px)",
		top: -24,
		"& > span": {
			backgroundColor: "#000",
			width: 24,
			height: 24,
		},
	},
	track: {
		height: 8,
		borderRadius: 4,
	},
	rail: {
		height: 8,
		borderRadius: 4,
	},
})(Slider)

const MeetingView = () => {
	// const notificationEnabled = CookieService.getAll().notification;
	const {
		user,
		participants,
		isLive,
		wentLive,
		videoDetails,
		meetingRoomId,
		classCode,
		avgRating,
		streamLoc,
		hasEnded,
		setValue,
	} = useAppContext()
	const { isMD } = useBreakpoints()
	const { meeting } = useDyteMeeting()
	const joined = useDyteSelector(m => m.self.roomJoined)
	const meetingParticipants = useDyteSelector(m =>
		m.participants.joined.toArray()
	)
	const selfParticipant = useDyteSelector(m => m.self)
	const { action, golive, joinedMeeting } = useMeetingEmitter()
	const [meetingStates, setMeetingStates] = useState({
		prefs: { muteNotificationSounds: false },
		activeSettings: false,
		meeting: "setup",
	})
	const [showWarning, setShowWarning] = useState(false)
	const [showConfirmStream, setShowConfirmStream] = useState(false)
	const [showStreamCountDown, setShowStreamCountDown] = useState(false)
	const [activeLeaveConfirmation, setActiveLeaveConfirmation] =
		useState(false)
	const [ytStreamStatus, setYtStreamStatus] = useState(null)

	// useEffect(() => {
	// 	if (!notificationEnabled)
	// 		setMeetingStates((prev) => ({
	// 			...prev,
	// 			prefs: { muteNotificationSounds: true, ...prev.prefs },
	// 		}));
	// }, [notificationEnabled]);

	const updateClassStatus = () => {
		if (activeLeaveConfirmation) {
			closeActiveLeaveConfirmation()
			joined && meeting.leaveRoom()
		}
		axios.get(`/v2/lms/classes/status/${classCode}`).then(resp => {
			setValue(state => ({
				...state,
				...resp.data.results.data,
				isPast: resp.data.results.data.hasEnded,
			}))
		})
	}
	const updateLive = liveStatus => {
		golive({
			roomId: meetingRoomId || classCode,
			classCode,
			isLive: liveStatus,
		})
		updateClassStatus()
		setValue(prev => ({ ...prev, isLive: liveStatus }))
	}

	const startStream = () => {
		axios
			.post(`/v2.5/lms/classes/${classCode}/dyte/meeting/recording/start`)
			.then(resp => {
				if (resp.data.results.data.status === "INVOKED") {
					updateLive(true)
					setShowStreamCountDown(true)
				}
			})
			.catch(e => {
				if (
					e.response.data.results.data.error.includes(
						"ERR_ANOTHER_RECORDING"
					)
				) {
					setValue(state => ({
						...state,
						isLive: true,
					}))
					setShowStreamCountDown(true)
				}
			})
	}
	const stopStream = () => {
		axios
			.put(`/v2.5/lms/classes/${classCode}/dyte/meeting/recording/stop`)
			.then(() => {
				if (streamLoc.url.includes("youtube"))
					stopYTLive(videoDetails.embedId)
				else updateLive(false)
			})
			.catch(e => {
				if (
					e.response.data.results.data.recording.type.includes(
						"ERROR_RECORDING_ALREADY_STOPPED"
					)
				) {
					setValue(state => ({
						...state,
						isLive: false,
					}))
				}
			})
	}
	const retryYtStream = () => {
		setYtStreamStatus(null)
		console.debug(videoDetails.embedId, "end again")
		stopYTLive(videoDetails.embedId)
	}

	const toggleLive = (liveStatus, leaving = false) => {
		if (liveStatus === true) {
			startStream()
		} else {
			!leaving && stopStream()
		}
	}
	const stopYTLive = id => {
		console.debug("@stopYTLive", id)
		setTimeout(() => {
			axios({
				method: "POST",
				url: `/v2/admin/classes/stream/youtube/end/${id}`,
			})
				.then(resp => {
					if (resp.data.results.data.lifeCycleStatus === "complete") {
						golive({
							roomId: meetingRoomId || classCode,
							classCode,
							isLive: false,
						})
						updateClassStatus()
					}
				})
				.catch(e => {
					setYtStreamStatus(e.response.data.results.data.reason)
					console.error(e.response.data.results.data.reason)
				})
		}, 2000)
	}

	const closeConfirmStream = () => setShowConfirmStream(false)
	const closeStreamCountDown = () => {
		setShowStreamCountDown(false)
		setValue(state => ({ ...state, wentLive: false }))
	}
	const closeActiveSettings = () =>
		setMeetingStates(state => ({ ...state, activeSettings: false }))
	const closeActiveLeaveConfirmation = () => setActiveLeaveConfirmation(false)
	const closeWarning = () => setShowWarning(false)

	const toggleMic = async () => {
		if (meeting.self.audioEnabled) {
			await meeting.self.disableAudio()
		} else {
			await meeting.self.enableAudio()
		}
	}
	const toggleCamera = async () => {
		if (meeting.self.videoEnabled) {
			await meeting.self.disableVideo()
		} else {
			await meeting.self.enableVideo()
		}
	}

	useHotkeys({ toggleMic, toggleCamera })

	const mergeWithParticipantIds = () => {
		const dyteParticipants = [
			Object.assign({}, selfParticipant),
			...meetingParticipants.map(p => Object.assign({}, p)),
		]
		return participants.map(p =>
			dyteParticipants.find(d => d.clientSpecificId === p.uid)
				? {
						...p,
						participantId: dyteParticipants.find(
							d => d.clientSpecificId === p.uid
						).id,
				  }
				: p
		)
	}

	useEffect(() => setShowStreamCountDown(wentLive), [wentLive])
	useEffect(() => {
		console.debug(ytStreamStatus)
		if (ytStreamStatus === null) return
		switch (ytStreamStatus) {
			case "errorStreamInactive":
				console.error("errorStreamInactive")
				retryYtStream()
				break
			case "redundantTransition":
				console.error("redundantTransition")
				updateLive(false)
				break
			case "invalidTransition":
				console.error("invalidTransition")
				retryYtStream()
				break
			case "liveBroadcastNotFound":
				console.error("The request is using an unsupported protocol.")
				break
			default:
				console.error("OOPS")
				break
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [ytStreamStatus])

	const roomLeft = () => {
		updateClassStatus()
		closeActiveLeaveConfirmation()
		toggleLive(false, true)
		action({
			metaData: {},
			roomId: meetingRoomId || classCode,
			action: "meetingLeft",
		})
		setValue(state => ({
			...state,
			participants: [
				...state.participants.map(p =>
					p.uid === user.uid
						? {
								...p,
								participantId: null,
						  }
						: p
				),
			],
			user: {
				...state.user,
				participantId: null,
			},
			roomJoined: false,
		}))
		closeClass("closeClass")
	}

	const participantJoined = participant => {
		setValue(state => ({
			...state,
			participants: state.participants.find(
				p => p.uid === participant.clientSpecificId
			)
				? [
						...state.participants.map(p =>
							p.uid === participant.clientSpecificId
								? {
										...p,
										participantId: participant.id,
								  }
								: p
						),
				  ]
				: state.participants,
		}))
	}

	const participantLeft = participant => {
		setValue(state => ({
			...state,
			participants: state.participants.find(
				p => p.uid === participant.clientSpecificId
			)
				? state.participants.map(p =>
						p.uid === participant.clientSpecificId
							? {
									...p,
									participantId: null,
							  }
							: p
				  )
				: state.participants,
		}))
	}

	const activeSpeaker = ({ peerId }) =>
		setValue(state => ({ ...state, activeSpeaker: peerId }))

	const audioUpdate = ({ audioEnabled }) =>
		action({
			metaData: {},
			roomId: meetingRoomId || classCode,
			action: audioEnabled ? "micOn" : "micOff",
		})

	const videoUpdate = ({ videoEnabled }) =>
		action({
			metaData: {},
			roomId: meetingRoomId || classCode,
			action: videoEnabled ? "cameraOn" : "cameraOff",
		})

	const screenShareUpdate = ({ screenShareEnabled }) =>
		action({
			metaData: {},
			roomId: meetingRoomId || classCode,
			action: screenShareEnabled ? "sharedScreen" : "stopScreenShared",
		})

	useEffect(() => {
		window.meeting = meeting ? meeting : null
		if (joined) {
			action({
				metaData: {},
				roomId: meetingRoomId || classCode,
				action: "joinedMeeting",
			})
			window.onbeforeunload = () => {
				meeting.leaveRoom()
				action({
					metaData: {},
					roomId: meetingRoomId || classCode,
					action: "meetingLeft",
				})
			}
			setMeetingStates(state => ({ ...state, meeting: "joined" }))
			setValue(state => ({
				...state,
				roomJoined: true,
			}))
			joinedMeeting({
				uid: user.uid,
				participantId: meeting.self.id,
				roomId: meetingRoomId || classCode,
			})
			meeting.self.on("roomLeft", roomLeft)
			meeting.participants.joined.on(
				"participantJoined",
				participantJoined
			)
			meeting.participants.joined.on("participantLeft", participantLeft)
			meeting.participants.on("activeSpeaker", activeSpeaker)
			meeting.self.on("audioUpdate", audioUpdate)
			meeting.self.on("videoUpdate", videoUpdate)
			meeting.self.on("screenShareUpdate", screenShareUpdate)

			return () => {
				meeting.self.off("roomLeft", roomLeft)
				meeting.participants.joined.off(
					"participantJoined",
					participantJoined
				)
				meeting.participants.joined.off(
					"participantLeft",
					participantLeft
				)
				meeting.participants.off("activeSpeaker", activeSpeaker)
				meeting.self.off("audioUpdate", audioUpdate)
				meeting.self.off("videoUpdate", videoUpdate)
				meeting.self.off("screenShareUpdate", screenShareUpdate)
			}
		}
		if (meeting)
			setValue(state => ({
				...state,
				participants: mergeWithParticipantIds(),
				user: {
					...state.user,
					participantId: meeting.self.id,
				},
			}))
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [joined])

	return (
		<>
			<div className="px-4 py-4 flex flex-col flex-grow rounded-md h-full">
				<div className="flex flex-grow w-full h-full">
					{!joined ? (
						<span className="setupScreen w-full">
							<DyteSetupScreen
								meeting={meeting}
								states={meetingStates}
								size={isMD ? "" : "sm"}
								onDyteStateUpdate={({ detail }) => {
									setMeetingStates(state => ({
										...state,
										activeSettings: detail.activeSettings,
									}))
								}}
							/>
						</span>
					) : (
						<span className="meetingControls w-full">
							<DyteGrid
								meeting={meeting}
								states={meetingStates}
								size={isMD ? "" : "sm"}
								aspectRatio={isMD ? "16:9" : "1:1"}
								onDyteStateUpdate={({ detail }) => {
									setMeetingStates(state => ({
										...state,
										...detail,
									}))
								}}
								config={extendConfig({
									root: {
										"dyte-screenshare-view": [
											["dyte-name-tag"],
											[
												"dyte-mic-toggle",
												{
													style: {
														position: "absolute",
														right: "12px",
														bottom: "12px",
													},
													size: "sm",
												},
											],
										],
									},
								})}
							/>
							<DyteParticipantsAudio
								className="absolute"
								meeting={meeting}
							/>
							{process.env.NODE_ENV === "production" && (
								<DyteNotifications
									className="absolute inset-4 top-[auto]"
									meeting={meeting}
									states={meetingStates}
									config={{
										config: {
											notifications: [
												"chat",
												"participant_joined",
												"participant_left",
											],
											notification_sounds: [
												"chat",
												"participant_joined",
											],
										},
									}}
								/>
							)}
						</span>
					)}
				</div>
			</div>

			<div className=" w-full flex flex-wrap items-center justify-between">
				{joined && (
					<div className="md:px-4 py-2 md:w-auto w-full grid md:grid-cols-6 md:grid-rows-1 grid-rows-2 grid-cols-3 gap-3 items-center justify-items-center meetingControls">
						<span className="shadow rounded-lg py-0.5">
							<DyteControlbarButton
								className="h-full"
								onClick={() => setShowConfirmStream(true)}
								label={isLive ? "STOP LIVE" : "GO LIVE"}
								icon={`<svg xmlns="http://www.w3.org/2000/svg" width="32px" height="32px" viewBox="0 0 32 32" id="icon"><defs><style>.cls-1{fill:#000000;}.cls-2{fill:none;}.cls-3{fill: red};</style></defs><path class="${
									isLive ? "cls-3" : "cls-1"
								}" d="M16,4A12,12,0,1,1,4,16,12,12,0,0,1,16,4m0-2A14,14,0,1,0,30,16,14,14,0,0,0,16,2Z" transform="translate(0)"/><path class="${
									isLive ? "cls-3" : "cls-1"
								}" d="M16,12a4,4,0,1,1-4,4,4,4,0,0,1,4-4m0-2a6,6,0,1,0,6,6,6,6,0,0,0-6-6Z" transform="translate(0)"/><rect id="_Transparent_Rectangle_" data-name="&lt;Transparent Rectangle&gt;" class="cls-2" width="32" height="32"/></svg>`}
								size={isMD ? "" : "sm"}
							/>
						</span>
						<span className="shadow rounded-lg py-0.5">
							<DyteMicToggle
								meeting={meeting}
								size={isMD ? "" : "sm"}
							/>
						</span>
						<span className="shadow rounded-lg py-0.5">
							<DyteCameraToggle
								meeting={meeting}
								size={isMD ? "" : "sm"}
							/>
						</span>
						<span className="shadow rounded-lg py-0.5">
							<DyteScreenShareToggle
								meeting={meeting}
								size={isMD ? "" : "sm"}
							/>
						</span>
						<span className="shadow rounded-lg py-0.5">
							<DyteSettingsToggle
								states={meetingStates}
								onDyteStateUpdate={({ detail }) => {
									setMeetingStates(state => ({
										...state,
										activeSettings: detail.activeSettings,
									}))
								}}
								size={isMD ? "" : "sm"}
							/>
						</span>
						<span className="shadow rounded-lg py-0.5">
							<DyteLeaveButton
								states={{ activeLeaveConfirmation }}
								onDyteStateUpdate={({ detail }) => {
									setActiveLeaveConfirmation(
										detail.activeLeaveConfirmation
									)
								}}
								size={isMD ? "" : "sm"}
							/>
						</span>
					</div>
				)}
				{!hasEnded && (
					<div className="flex justify-center md:min-w-[30%] sm:min-w-[35%] min-w-full">
						<div className="flex flex-grow items-center my-auto w-progress max-w-progress progressContainer">
							<p className="w-10 h-10 m-0 mr-5 flex items-center">
								No
							</p>
							<span className="w-full relative">
								<BoringSlider
									valueLabelDisplay="auto"
									aria-label="boring slider"
									defaultValue={10}
									value={parseInt(avgRating.toFixed(2))}
									valueLabelFormat={`${parseInt(avgRating)}`}
								/>
								<span className="absolute inset-x-0 text-sm text-gray-500 font-medium w-full sm:-bottom-1 bottom-0 text-center">
									Understanding Level
								</span>
							</span>
							<p className="w-10 h-10 m-0 ml-5 flex items-center">
								Yes
							</p>
						</div>
					</div>
				)}
			</div>

			<ShowMicRequestModal />
			<ShowCamRequestModal />
			<ShowConfirmStreamStateModal
				showConfirmStream={showConfirmStream}
				onConfirm={() => toggleLive(!isLive)}
				closeConfirmStream={closeConfirmStream}
			/>
			<ShowWarningModal
				showWarning={showWarning}
				onStop={() => {
					toggleLive(false)
					closeWarning()
				}}
				closeWarning={closeWarning}
			/>

			<ShowStartStreamCountDownModal
				active={showStreamCountDown}
				close={closeStreamCountDown}
			/>
			{meetingStates.activeSettings ? (
				<ShowMeetingSettingsModal
					meetingStates={meetingStates}
					setMeetingStates={setMeetingStates}
					closeActiveSettings={closeActiveSettings}
				/>
			) : null}
			{activeLeaveConfirmation ? (
				<ShowMeetingLeaveConfirmationModal
					activeLeaveConfirmation={activeLeaveConfirmation}
					setActiveLeaveConfirmation={setActiveLeaveConfirmation}
					closeActiveLeaveConfirmation={closeActiveLeaveConfirmation}
				/>
			) : null}
		</>
	)
}

export default MeetingView
