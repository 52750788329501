import { CheckIcon, PaperAirplaneIcon } from "@heroicons/react/outline"
import { useAppContext } from "context"
import { nanoid } from "nanoid"
import { useState } from "react"
import { usePasteBinEmitter } from "utils/Emits"

const AgendaForm = ({ setEdit, edit }) => {
	const { classCode, pasteBinRoomId, setValue } = useAppContext()
	const { addAgenda, updateAgenda } = usePasteBinEmitter()
	const [input, setInput] = useState(edit ? edit.value : "")
	const [placeholder] = useState(edit ? edit.value : "Add Agenda")

	const handleSubmit = e => {
		e?.preventDefault()

		if (!input || /^\s*$/.test(input)) {
			return
		}
		if (!edit) {
			const agenda = {
				id: nanoid(16),
				text: input,
				isCompleted: false,
			}
			addAgenda({
				classCode,
				roomId: pasteBinRoomId || classCode,
				agenda,
			})
			setValue(state => ({
				...state,
				agendas: [...state.agendas, agenda],
			}))
		} else {
			const agenda = {
				id: edit?.id,
				text: input,
				isCompleted: false,
			}
			updateAgenda({
				roomId: pasteBinRoomId || classCode,
				classCode,
				agenda,
			})
			setValue(state => ({
				...state,
				agendas: state.agendas.map(item =>
					item.id === agenda.id ? agenda : item
				),
			}))
			setEdit({
				id: null,
				value: "",
			})
		}
		setInput("")
	}

	return (
		<>
			<div className="px-4 py-4 sm:px-6">
				<form
					onSubmit={handleSubmit}
					className="relative"
				>
					<input
						className="block w-full pr-3 py-2 border border-transparent rounded-md leading-5 bg-gray-300 bg-opacity-25 text-gray-500 placeholder-gray-500 focus:outline-none focus:bg-white focus:ring-0 focus:placeholder-gray-500 focus:text-gray-900 sm:text-sm focus:border-gray-300 focus:shadow"
						type="text"
						value={input}
						placeholder={placeholder || "Add Agenda"}
						name="text"
						onChange={e => {
							setInput(e.target.value)
						}}
					/>
					<button
						type="submit"
						disabled={!input}
						className="absolute inset-y-0 right-0 inline-flex items-center px-2 text-sm text-gray-500 rounded-r-md border-0 bg-gray-50 disabled:opacity-50"
					>
						{edit ? (
							<CheckIcon className="w-6 h-6" />
						) : (
							<PaperAirplaneIcon className="w-6 h-6 rotate-90" />
						)}
					</button>
				</form>
			</div>
		</>
	)
}

export default AgendaForm
