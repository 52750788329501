import { useAppContext } from "context"
import React, { useEffect } from "react"
import { closeClass } from "utils/closeClass"
import { useChatEmitter } from "utils/Emits"
import groupifyChat from "utils/groupifyChat"
import { isInViewport, scrollIntoView } from "utils/viewPort"

const ChatEvents = () => {
	const { socketPoints, setValue } = useAppContext()
	const { joinChatSocket } = useChatEmitter()

	const chatSocketConnect = () => {
		// joinChatSocket();
		console.debug("@chatSocket Connected via default")
	}

	// const chatSocketConnected = () => {
	// 	console.debug("@chatSocket Connected via custom");
	// 	joinChatSocket();
	// });

	const chatSocketIoReconnect = attempt => {
		console.debug("@chatSocket reconnect", attempt)
		joinChatSocket()
	}

	const chatSocketIoReconnectAttempt = attempt => {
		console.debug("@chatSocket reconnect_attempt", attempt)
	}
	const chatSocketIoReconnectError = error => {
		console.debug("@chatSocket reconnect_error", error.message)
	}
	const chatSocketIoReconnectFailed = () => {
		console.debug("@chatSocket reconnect_failed")
		setValue(state => ({
			...state,
			socketError: true,
			socketState: {
				...state.socketState,
				connection: {
					...state.socketState.connection,
					chatSocket: false,
				},
				error: { ...state.socketState.error, chatSocket: false },
			},
		}))
		closeClass("chatSocketError")
	}

	const chatSocketDisconnect = reason => {
		console.debug("@chatSocket Disconnected: ", reason)
		setValue(state => ({
			...state,
			socketState: {
				...state.socketState,
				connection: {
					...state.socketState.connection,
					chatSocket: true,
				},
				error: { ...state.socketState.error, chatSocket: false },
			},
		}))
	}

	const chatSocketError = data => {
		console.error("@chatSocket ERROR: ", data)
		setValue(state => ({
			...state,
			socketError: true,
			socketState: {
				...state.socketState,
				connection: {
					...state.socketState.connection,
					chatSocket: true,
				},
				error: { ...state.socketState.error, chatSocket: false },
			},
		}))
		closeClass("chatSocketError")
	}

	const chatSocketJoined = data => {
		console.debug("@chatSocket Joined: ", data)
		setValue(state => ({
			...state,
			chatRoomId: data.roomId,
			socketState: {
				...state.socketState,
				connection: {
					...state.socketState.connection,
					chatSocket: true,
				},
				error: { ...state.socketState.error, chatSocket: false },
			},
		}))
	}

	const chatSocketMessage = ({ message }) => {
		console.debug("@chatSocket.on('message'):", message)
		setValue(state => ({
			...state,
			chats: groupifyChat(
				[{ ...message, isDeleted: false }],
				state.chats.reverse()
			).reverse(),
		}))

		if (isInViewport("#chatEnd")) {
			setValue(state => ({
				...state,
				unread: {
					...state.unread,
					chat: 0,
				},
			}))
			scrollIntoView("#chatContainer")
		} else
			setValue(state => ({
				...state,
				unread: {
					...state.unread,
					chat: 1,
				},
			}))
	}

	const chatSocketSelfMessage = ({ _id, tempId }) => {
		console.debug("@chatSocket.on('selfMessage'):", { _id, tempId })
		setValue(state => ({
			...state,
			chats: [
				...state.chats.map(c =>
					c.tempId === tempId ? { ...c, _id } : c
				),
			],
		}))
		const chatContainer = document.getElementById("chatContainer")
		chatContainer &&
			chatContainer.scrollTo({
				top: chatContainer.scrollHeight,
				behavior: "smooth",
			})
	}

	const chatSocketDelete = ({ _id }) => {
		console.debug("@chatSocket.on('delete'):", _id)
		setValue(state => ({
			...state,
			chats: state.chats.map(chat =>
				chat._id === _id
					? { ...chat, isDeleted: true }
					: chat.replyTo?._id === _id
					? { ...chat, replyTo: { ...chat.replyTo, isDeleted: true } }
					: chat
			),
		}))
	}

	useEffect(() => {
		socketPoints.chatSocket.on("connect", chatSocketConnect)
		// socketPoints.chatSocket.on("connected", chatSocketConnected);
		socketPoints.chatSocket.io.on("reconnect", chatSocketIoReconnect)
		socketPoints.chatSocket.io.on(
			"reconnect_attempt",
			chatSocketIoReconnectAttempt
		)
		socketPoints.chatSocket.io.on(
			"reconnect_error",
			chatSocketIoReconnectError
		)
		socketPoints.chatSocket.io.on(
			"reconnect_failed",
			chatSocketIoReconnectFailed
		)
		socketPoints.chatSocket.on("disconnect", chatSocketDisconnect)
		socketPoints.chatSocket.on("error", chatSocketError)
		socketPoints.chatSocket.on("joined", chatSocketJoined)
		socketPoints.chatSocket.on("message", chatSocketMessage)
		socketPoints.chatSocket.on("selfMessage", chatSocketSelfMessage)
		socketPoints.chatSocket.on("delete", chatSocketDelete)

		return () => {
			socketPoints.chatSocket.off("connect", chatSocketConnect)
			// socketPoints.chatSocket.off("connected", chatSocketConnected);
			socketPoints.chatSocket.io.off("reconnect", chatSocketIoReconnect)
			socketPoints.chatSocket.io.off(
				"reconnect_attempt",
				chatSocketIoReconnectAttempt
			)
			socketPoints.chatSocket.io.off(
				"reconnect_error",
				chatSocketIoReconnectError
			)
			socketPoints.chatSocket.io.off(
				"reconnect_failed",
				chatSocketIoReconnectFailed
			)
			socketPoints.chatSocket.off("disconnect", chatSocketDisconnect)
			socketPoints.chatSocket.off("error", chatSocketError)
			socketPoints.chatSocket.off("joined", chatSocketJoined)
			socketPoints.chatSocket.off("message", chatSocketMessage)
			socketPoints.chatSocket.off("selfMessage", chatSocketSelfMessage)
			socketPoints.chatSocket.off("delete", chatSocketDelete)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	return <></>
}

export default ChatEvents
