import { RefreshIcon } from "@heroicons/react/outline"
import { IconButton } from "@material-ui/core"
import { useAppContext } from "context"
import classNames from "utils/classNames"
import { usePasteBinEmitter } from "utils/Emits"
import Agenda from "./Agenda"
import AgendaForm from "./AgendaForm"

const AgendaList = () => {
	const { socketState } = useAppContext()
	const { joinPasteBinSocket } = usePasteBinEmitter()

	return (
		<>
			<div className="relative">
				{(socketState.connection.pasteBinSocket === false ||
					socketState.error.pasteBinSocket === true) && (
					<div className="absolute inset-0 w-full z-50 bg-transparent flex justify-center items-center">
						<IconButton onClick={joinPasteBinSocket}>
							<RefreshIcon className="w-6 h-6 text-red-500 hover:animate-spin" />
						</IconButton>
					</div>
				)}
				<div
					className={classNames(
						socketState.connection.pasteBinSocket === false ||
							socketState.error.pasteBinSocket === true
							? "blur-[2px]"
							: ""
					)}
				>
					<div className="flex flex-col w-full md:h-screen h-sideContainer rounded-bl-max">
						{/* <h1 className='text-2xl text-slate-800'>What's the Plan for Today??</h1> */}
						<AgendaForm />
						<Agenda />
					</div>
				</div>
			</div>
		</>
	)
}

export default AgendaList
