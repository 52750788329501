import { formatDistanceToNow } from "date-fns"
import { useState } from "react"
import classNames from "utils/classNames"
import urlify from "utils/urlify"

const Message = ({ msgGroup }) => {
	const [isReadMore, setIsReadMore] = useState(true)
	return (
		<>
			<div className="px-3 py-4 hover:bg-gray-50 flex space-x-2">
				<img
					src={
						msgGroup.user.profileImageUrl
							? msgGroup.user.profileImageUrl
							: `https://avatars.dicebear.com/api/initials/${msgGroup.user?.name}.svg`
					}
					alt=""
					className="h-5 rounded-md square"
				/>
				<div className="w-full flex flex-col space-y-1">
					<div className="flex items-center space-x-2">
						<span
							className="text-sm font-semibold text-gray-900"
							// style={
							// 	["admin", "moderator", "trainer"].includes(
							// 		msgGroup.user.role
							// 	)
							// 		? {
							// 			color: getHex(msgGroup.user.uid),
							// 		}
							// 		: {}
							// }
						>
							{msgGroup.user.name}
						</span>
						{["trainer", "admin", "moderator"].includes(
							msgGroup.user.role.toLowerCase()
						) ? (
							<span
								className={classNames(
									"inline-flex items-center px-2.5 py-0.5 space-x-1 rounded text-xxs font-medium",
									msgGroup.user.role === "trainer"
										? "bg-purple-100 text-purple-800"
										: msgGroup.user.role === "trainer"
										? "bg-yellow-100 text-yellow-800"
										: "bg-green-100 text-green-800"
								)}
							>
								<span>
									{msgGroup.user.role
										.charAt(0)
										.toUpperCase() +
										msgGroup.user.role.slice(1)}
								</span>
							</span>
						) : null}
					</div>
					<div className="flex flex-col space-y-1 items-start w-full">
						<div className="relative flex space-x-1 items-center w-full group-scoped message">
							<div className="flex flex-col">
								{msgGroup.messages.map(
									({ _id, message: msg }) => (
										<div
											className={classNames(
												"mx-0 !leading-[18px] text-sm font-normal overflow-anywhere",
												urlify(msg).urls.length &&
													"!mt-2"
											)}
											key={_id}
										>
											<div
												className="!inline !m-0 leading-[18px] text-sm"
												dangerouslySetInnerHTML={{
													__html: isReadMore
														? msg.length > 150
															? `${
																	urlify(
																		msg.slice(
																			0,
																			150
																		)
																	).text
															  }...`
															: urlify(msg).text
														: urlify(msg).text,
												}}
											/>
											{msg._id}
											{msg.length > 150 && (
												<div
													className="cursor-pointer text-xs text-lu text-center"
													onClick={() =>
														setIsReadMore(
															!isReadMore
														)
													}
												>
													{isReadMore
														? "Show More"
														: "Show Less"}
												</div>
											)}
										</div>
									)
								)}
							</div>
						</div>
					</div>
					<span className="text-xs text-gray-300">
						{formatDistanceToNow(new Date(msgGroup.createdAt), {
							addSuffix: true,
							includeSeconds: true,
						})}
					</span>
				</div>
			</div>
		</>
	)
}

export default Message
