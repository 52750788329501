import { DyteLeaveMeeting } from "@dytesdk/react-ui-kit"
import { useDyteMeeting } from "@dytesdk/react-web-core"
import { Dialog, Transition } from "@headlessui/react"
import { Fragment } from "react"

const ShowMeetingLeaveConfirmation = ({
	activeLeaveConfirmation,
	setActiveLeaveConfirmation,
	closeActiveLeaveConfirmation,
}) => {
	const { meeting } = useDyteMeeting()

	return (
		<>
			<Transition
				appear
				show={activeLeaveConfirmation}
				as={Fragment}
			>
				<Dialog
					as="div"
					className="fixed inset-0 z-10 overflow-y-auto"
					onClose={closeActiveLeaveConfirmation}
				>
					<div className="min-h-screen px-4 text-center">
						<Transition.Child
							as={Fragment}
							enter="ease-out duration-300"
							enterFrom="opacity-0"
							enterTo="opacity-100"
							leave="ease-in duration-200"
							leaveFrom="opacity-100"
							leaveTo="opacity-0"
						>
							<Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-50" />
						</Transition.Child>

						{/* This element is to trick the browser into centering the modal contents. */}
						<span
							className="inline-block h-screen align-middle"
							aria-hidden="true"
						>
							&#8203;
						</span>
						<Transition.Child
							as={Fragment}
							enter="ease-out duration-300"
							enterFrom="opacity-0 scale-95"
							enterTo="opacity-100 scale-100"
							leave="ease-in duration-200"
							leaveFrom="opacity-100 scale-100"
							leaveTo="opacity-0 scale-95"
						>
							<div className="inline-block my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl leaveModal">
								<DyteLeaveMeeting
									className="w-full h-full"
									states={{ activeLeaveConfirmation }}
									onDyteStateUpdate={({ detail }) => {
										setActiveLeaveConfirmation(
											detail.activeLeaveConfirmation
										)
									}}
									meeting={meeting}
								/>
							</div>
						</Transition.Child>
					</div>
				</Dialog>
			</Transition>
		</>
	)
}

export default ShowMeetingLeaveConfirmation
