const CookieService = {
	getAll() {
		return (
			{
				accessToken: localStorage.getItem("luClass.token"),
				refreshToken: localStorage.getItem("luClass.refresh"),
				notification:
					localStorage.getItem("luClass.notification") === null
						? false
						: JSON.parse(
								localStorage.getItem("luClass.notification")
						  ),
			} || false
		)
		// return Cookie.get() || false;
	},

	getLocalRefreshToken() {
		return localStorage.getItem("luClass.refresh") || false
		// return Cookie.get("luClass.refresh") || false;
	},

	getLocalAccessToken() {
		return localStorage.getItem("luClass.token") || false
		// return Cookie.get("luClass.token") || false;
	},

	updateLocalAccessToken(token) {
		localStorage.setItem("luClass.token", token)
		// Cookie.set("luClass.token", token);
	},

	setTokens({ accessToken, refreshToken }) {
		localStorage.setItem("luClass.token", accessToken)
		// Cookie.set("luClass.token", accessToken);
		localStorage.setItem("luClass.refresh", refreshToken)
		// Cookie.set("luClass.refresh", refreshToken);
	},

	setNotificationToken() {
		const notificationEnabled =
			localStorage.getItem("luClass.notification") === null
				? false
				: JSON.parse(localStorage.getItem("luClass.notification"))
		localStorage.setItem("luClass.notification", notificationEnabled)
	},

	toggleNotificationToken() {
		const notificationEnabled =
			localStorage.getItem("luClass.notification") === null
				? false
				: JSON.parse(localStorage.getItem("luClass.notification"))
		localStorage.setItem("luClass.notification", !notificationEnabled)
	},

	removeTokens() {
		localStorage.removeItem("luClass.token")
		// Cookie.remove("luClass.token");
		localStorage.removeItem("luClass.refresh")
		// Cookie.remove("luClass.refresh");
	},
}

export default CookieService
