import {
	DotsVerticalIcon,
	PencilIcon,
	TrashIcon,
} from "@heroicons/react/outline"
import { IconButton, Menu, MenuItem, Typography } from "@material-ui/core"
import { useAppContext } from "context"
import { useState } from "react"
import classNames from "utils/classNames"
import { usePasteBinEmitter } from "utils/Emits"
import AgendaForm from "./AgendaForm"

const AgendaOptions = ({ agenda, setEdit }) => {
	const { classCode, pasteBinRoomId, setValue } = useAppContext()
	const { deleteAgenda } = usePasteBinEmitter()
	const [anchorEl, setAnchorEl] = useState(null)

	const handleClick = event => {
		setAnchorEl(event.currentTarget)
	}

	const handleClose = () => {
		setAnchorEl(null)
	}

	const handleRemoveAgenda = agenda => {
		deleteAgenda({
			roomId: pasteBinRoomId || classCode,
			classCode,
			agenda,
		})
		setValue(state => ({
			...state,
			agendas: state.agendas.filter(item => item.id !== agenda.id),
		}))
	}

	return (
		<>
			<IconButton
				aria-haspopup="true"
				onClick={e => handleClick(e)}
				className="!min-w-[unset] inline-flex justify-center !p-0 text-sm font-medium"
			>
				<DotsVerticalIcon
					className="w-4 h-4"
					aria-hidden="true"
				/>
			</IconButton>
			<Menu
				id="simple-menu"
				anchorEl={anchorEl}
				keepMounted
				open={Boolean(anchorEl)}
				onClose={handleClose}
				onClick={handleClose}
				className="!shadow"
				anchorOrigin={{
					vertical: "top",
					horizontal: "left",
				}}
				transformOrigin={{
					vertical: "top",
					horizontal: "right",
				}}
			>
				<MenuItem
					onClick={() =>
						!agenda.isCompleted &&
						setEdit({
							id: agenda.id,
							value: agenda.text,
						})
					}
					className="flex items-center space-x-2 !px-2"
				>
					<PencilIcon
						className={classNames(
							"w-5 h-5",
							agenda.isCompleted
								? "text-gray-500"
								: "text-gray-900 hover:scale-110"
						)}
					/>
					<span className="text-sm text-gray-900">Edit</span>
				</MenuItem>
				<MenuItem
					onClick={() =>
						!agenda.isCompleted && handleRemoveAgenda(agenda)
					}
					className="flex items-center space-x-2 !px-2"
				>
					<TrashIcon
						className={classNames(
							"w-5 h-5",
							agenda.isCompleted
								? "text-red-400"
								: "text-red-500 hover:scale-110"
						)}
					/>
					<span className="text-sm text-gray-900">Delete</span>
				</MenuItem>
			</Menu>
		</>
	)
}

const Agenda = () => {
	const { agendas, classCode, pasteBinRoomId, setValue } = useAppContext()
	const { updateAgenda } = usePasteBinEmitter()
	const [edit, setEdit] = useState({
		id: null,
		value: "",
	})

	const handleCompleteAgenda = agenda => {
		updateAgenda({
			roomId: pasteBinRoomId || classCode,
			classCode,
			agenda,
		})
		setValue(state => ({
			...state,
			agendas: state.agendas.map(item => {
				if (item.id === agenda.id) {
					item.isCompleted = !item.isCompleted
				}
				return item
			}),
		}))
	}

	return (
		<ul className="flex flex-col flex-grow overflow-y-scroll scrollbar-hide divide-y divide-gray-200">
			{agendas.length ? (
				agendas.map((agenda, idx) =>
					agenda.id === edit.id ? (
						<AgendaForm
							edit={edit}
							setEdit={setEdit}
						/>
					) : (
						<li
							key={agenda.id}
							className={classNames(
								"flex justify-between items-center text-black bg-white px-4 py-4 sm:px-6 select-none"
								// agenda.isCompleted ? "opacity-40" : ""
							)}
						>
							<span className="flex-1 flex space-x-4 cursor-pointer select-text">
								<div className="flex items-center">
									<input
										type="checkbox"
										className="focus:ring-0 focus:outline-none h-4 w-4 text-lu border-gray-300 rounded"
										checked={agenda.isCompleted}
										onChange={() =>
											handleCompleteAgenda({
												...agenda,
												isCompleted:
													!agenda.isCompleted,
											})
										}
									/>
								</div>
								<div
									className={classNames(
										"flex-1 text-sm",
										agenda.isCompleted
											? "text-gray-500"
											: "text-gray-900 "
									)}
								>
									{agenda.text}
								</div>
							</span>
							<div
								className={classNames(
									"flex items-center mx-1 text-2xl space-x-2",
									agenda.isCompleted
										? "cursor-not-allowed"
										: "cursor-pointer"
								)}
							>
								{/* <PencilIcon
									className={classNames('w-5 h-5', agenda.isCompleted ? 'text-gray-500' : 'text-gray-900 hover:scale-110')}
									onClick={() =>
										!agenda.isCompleted &&
										setEdit({
											id: agenda.id,
											value: agenda.text,
										})
									}
								/>
								<TrashIcon
									className={classNames('w-5 h-5', agenda.isCompleted ? 'text-red-400' : 'text-red-500 hover:scale-110')}
									onClick={() =>
										!agenda.isCompleted && handleRemoveAgenda(agenda)}
								/> */}

								{!agenda.isCompleted && (
									<AgendaOptions
										agenda={agenda}
										setEdit={setEdit}
									/>
								)}
							</div>
						</li>
					)
				)
			) : (
				<div className="flex flex-col justify-center items-center space-x-1 text-black h-full select-none space-y-8">
					<img
						src="/assets/img/noAgenda.png"
						alt="No Agenda's Listed"
						className="md:w-[60%] w-[25%]"
					/>
					<Typography className="text-gray-500">
						No Agenda's Listed
					</Typography>
				</div>
			)}
		</ul>
	)
}

export default Agenda
