import { useEffect } from "react"

const useHotkeys = actions => {
	useEffect(() => {
		let keysPressed = {}
		document.addEventListener("keydown", event => {
			keysPressed[event.key] = true

			if (keysPressed["Control"] && event.key === "d") {
				event.preventDefault()
				actions.toggleMic()
			} else if (keysPressed["Control"] && event.key === "e") {
				event.preventDefault()
				actions.toggleCamera()
			}
		})

		document.addEventListener("keyup", event => {
			delete keysPressed[event.key]
		})
		return () => {
			document?.removeEventListener("keyup", event => {
				delete keysPressed[event.key]
				keysPressed = {}
			})
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])
	return null
}

export default useHotkeys
