export const closeClass = msg => {
	console.debug(`@Iframe: ${msg}`)
	const urls = {
		production: `https://trainer.letsupgrade.in`,
		development: `http://localhost:3003`,
	}

	const classCode = window.localStorage.getItem("classCode")

	const route = classCode ? `/classes/${classCode}` : "/"

	if (window.opener === null) {
		console.error("Opener missing")
		window.open(`${urls[process.env.NODE_ENV]}${route}`)
	}

	// window.opener.postMessage(msg, "*");
	// window.opener.focus();
	window.close()
}
