import { PaperAirplaneIcon } from "@heroicons/react/outline"
import { CheckCircleIcon, XCircleIcon } from "@heroicons/react/solid"
import axios from "axios"
import { useAppContext } from "context"
import { nanoid } from "nanoid"
import { useState } from "react"
import { defaultStyles, FileIcon } from "react-file-icon"
import { usePasteBinEmitter } from "utils/Emits"
import urlify from "utils/urlify"

const Input = () => {
	const [input, setInput] = useState("")
	const [resource, setResource] = useState("")
	const { token, classCode, pasteBinRoomId, setValue } = useAppContext()
	const { sendResource } = usePasteBinEmitter()
	const [file, setFile] = useState("")
	const [fileData, setFileData] = useState(false)
	const [fileSelected, setFileSelected] = useState(false)
	const [fileUploading, setFileUploading] = useState(false)
	const [fileUploaded, setFileUploaded] = useState(false)
	const [fileTooLarge, setFileTooLarge] = useState(false)

	const getFileSizeConverted = _size => {
		let fSExt = ["Bytes", "KB", "MB", "GB"],
			i = 0
		while (_size > 900) {
			_size /= 1024
			i++
		}
		const size = Math.round(_size * 100) / 100
		return { i, size, fileSize: size + " " + fSExt[i] }
	}

	const handleSendResource = resource => {
		sendResource(resource)
		console.debug({ ...resource, createdAt: new Date().toISOString() })
		setValue(state => ({
			...state,
			resources: [
				{ ...resource, createdAt: new Date().toISOString() },
				...state.resources,
			],
		}))
	}

	const sendMessage = e => {
		e.preventDefault()
		if (input?.trim() === "") return
		const urlified = urlify(input)
		handleSendResource({
			roomId: pasteBinRoomId || classCode,
			tempId: nanoid(16),
			link: urlified.urls,
			source: "input",
			ext: null,
			text: urlified.text,
			name: null,
			isImage: null,
			isVisible: true,
		})
		setInput("")
	}

	const sendFile = e => {
		e.preventDefault()
		const urlified = urlify(input)
		handleSendResource({
			...resource,
			text: urlified.text,
			isVisible: true,
		})
		setFileSelected(false)
		setFileUploading(false)
		setFileUploaded(false)
		setFileData({})
		setFile("")
		setInput("")
	}

	const handleFileChange = e => {
		const file = e.target.files[0]
		const _size = file.size
		const { i, size, fileSize } = getFileSizeConverted(_size)
		if (i >= 2 ? (size >= 10 ? true : false) : false) {
			setFileTooLarge(true)
			setFileUploading(false)
			setFileUploaded(false)
			setFileSelected(false)
			setFile("")
			setFileData(null)
			return
		}

		setFileTooLarge(false)
		setFile("")
		setFileSelected(true)
		setFileUploading(true)
		setFileUploaded(false)
		setFileData({
			name: file.name,
			ext: file.name.split(".").at(-1),
			isImage: file.type.split("/")[0] === "image",
			size: fileSize,
		})
		const formData = new FormData()
		formData.append("image", file)
		axios
			.post(
				`https://service.letsupgrade.in/v1/pastebin/${classCode}`,
				formData,
				{
					headers: {
						"Content-Type": "multipart/form-data",
						Authorization: `Bearer ${token}`,
					},
				}
			)
			.then(resp => {
				setFileUploading(false)
				setFileUploaded(true)
				setResource({
					tempId: nanoid(16),
					roomId: pasteBinRoomId || classCode,
					link: resp.data?.results.url,
					source: "file",
					text: "",
					name: file.name,
					size: fileSize,
					ext: file.name.split(".").at(-1),
					isImage: file.type.split("/")[0] === "image",
				})
				setFile("")
			})
			.catch(err => {
				console.error(err)
				setFileUploading(false)
				setFileUploaded(false)
				setFile("")
			})
	}

	return (
		<>
			{fileSelected && (
				<div className="flex flex-col px-4 py-4 sm:px-6 bg-gray-100">
					<div className="flex justify-between">
						<div className="flex items-center space-x-2">
							<div className="flex content-center justify-center h-10 square">
								<FileIcon
									extension={fileData.ext}
									{...defaultStyles[fileData.ext]}
								/>
							</div>
							<div className="min-w-0 flex flex-col flex-1 px-4 md:grid md:gap-1">
								<span className="w-full text-sm font-medium truncate text-gray-900 self-center break-words">
									{fileData.name}
								</span>
								<span className="flex space-x-2">
									{fileData.size && (
										<span className="flex-shrink-0 whitespace-nowrap text-xs text-gray-400">
											{fileData.size}
										</span>
									)}
								</span>
							</div>
						</div>

						{fileUploading && (
							<div className="flex items-center justify-center">
								<div
									style={{
										borderTopColor: "transparent",
									}}
									className="w-6 h-6 border-2 border-gray-900 border-solid rounded-full animate-spin"
								></div>
							</div>
						)}
						{fileUploaded && (
							<div className="flex flex-col justify-between">
								<div className="flex justify-center m-1">
									<button
										className="px-4 py-2 font-bold text-white bg-green-500 rounded-md hover:bg-green-700"
										onClick={sendFile}
									>
										<CheckCircleIcon className="w-4 h-4" />
									</button>
								</div>
								<div className="flex justify-center m-1">
									<button
										className="px-4 py-2 font-bold text-white bg-red-500 rounded-md hover:bg-red-700"
										onClick={() => {
											setFileSelected(false)
											setFileUploading(false)
											setFileUploaded(false)
											setFileData({})
										}}
									>
										<XCircleIcon className="w-4 h-4" />
									</button>
								</div>
							</div>
						)}
					</div>
				</div>
			)}
			<div className="relative flex p-3 shadow-sm rounded-bl-max border-t-[1px]">
				{fileTooLarge && (
					<div className="absolute -top-6 inset-x-0 flex justify-center z-50 bg-red-100 border-b-[1px] border-gray-300">
						<span className="p-1 text-red-500 text-xs">
							Your file is too large. Please upload file below
							10MB
						</span>
					</div>
				)}
				<div className="relative flex-grow">
					<form onSubmit={sendMessage}>
						<input
							type="text"
							value={input}
							onChange={e => setInput(e.target.value)}
							placeholder="Enter Resource"
							className="flex-1 block w-full px-4 py-2 pr-10 border border-transparent rounded-l-max sm:text-sm focus:outline-none focus:ring-0 focus:border-transparent"
						/>
						<button
							type="submit"
							className="absolute inset-y-0 right-0 inline-flex items-center px-2 text-sm text-gray-500 border border-l-0 border-transparent"
						>
							<PaperAirplaneIcon className="w-6 h-6 rotate-90" />
						</button>
					</form>
				</div>
				<div className="relative">
					<button className="inline-flex items-center h-full px-2 text-sm text-gray-500 border border-l-0 border-transparent rounded-r-max">
						<svg
							xmlns="http://www.w3.org/2000/svg"
							className="w-6 h-6"
							fill="none"
							viewBox="0 0 24 24"
							stroke="currentColor"
						>
							<path
								strokeLinecap="round"
								strokeLinejoin="round"
								strokeWidth={2}
								d="M15.172 7l-6.586 6.586a2 2 0 102.828 2.828l6.414-6.586a4 4 0 00-5.656-5.656l-6.415 6.585a6 6 0 108.486 8.486L20.5 13"
							/>
						</svg>
					</button>
					<input
						className="absolute inset-0 block w-full px-4 py-2 opacity-0 cursor-pointer"
						type="file"
						value={file}
						onChange={handleFileChange}
					/>
				</div>
			</div>
		</>
	)
}

export default Input
