import { Dialog, Transition } from "@headlessui/react"
import { Fragment, useEffect, useRef, useState } from "react"
import classNames from "utils/classNames"

const ShowStartStreamCountDown = ({ active, close }) => {
	const [ready, setReady] = useState(false)
	const [readyMsg, setReadyMsg] = useState("")
	const [time, setTime] = useState(5)
	const timerRef = useRef()
	const readyRef = useRef()
	let readyMsgIdx = 0
	const msgs = [
		{ label: "Ready", class: "text-3xl" },
		{ label: "Set", class: "text-4xl" },
		{ label: "Go", class: "text-5xl" },
	]

	const startTimer = () => {
		timerRef.current = setInterval(() => setTime(state => state - 1), 1000)
	}

	const reset = () => {
		clearInterval(readyRef.current)
		setTimeout(() => setReady(false), 500)
		setTime(5)
		close()
	}

	useEffect(() => {
		if (active) startTimer()
	}, [active])

	useEffect(() => {
		if (ready) {
			setReadyMsg(msgs[readyMsgIdx])
			readyMsgIdx++
			readyRef.current = setInterval(() => {
				setReadyMsg(msgs[readyMsgIdx])
				readyMsgIdx++
				if (readyMsgIdx === 4) {
					reset()
				}
			}, 1000)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [ready])

	useEffect(() => {
		if (time === 3) {
			setReady(true)
			clearInterval(timerRef.current)
		}
	}, [time])

	return (
		<>
			<Transition
				appear
				show={active}
				as={Fragment}
			>
				<Dialog
					as="div"
					className="fixed inset-0 z-10 overflow-y-auto"
					onClose={() => false}
				>
					<div className="min-h-screen px-4 text-center">
						<Transition.Child
							as={Fragment}
							enter="ease-out duration-300"
							enterFrom="opacity-0"
							enterTo="opacity-100"
							leave="ease-in duration-200"
							leaveFrom="opacity-100"
							leaveTo="opacity-0"
						>
							<Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-70" />
						</Transition.Child>

						{/* This element is to trick the browser into centering the modal contents. */}
						<span
							className="inline-block h-screen align-middle"
							aria-hidden="true"
						>
							&#8203;
						</span>
						<Transition.Child
							as={Fragment}
							enter="ease-out duration-300"
							enterFrom="opacity-0 scale-95"
							enterTo="opacity-100 scale-100"
							leave="ease-in duration-200"
							leaveFrom="opacity-100 scale-100"
							leaveTo="opacity-0 scale-95"
						>
							<div className="inline-block w-full max-w-md p-6 my-8 overflow-hidden align-middle transition-all transform text-center select-none text-white">
								{!ready ? (
									<span className="flex flex-col">
										<span className="text-xl">
											Going Live In
										</span>
										<span className="text-8xl animate-invertPing leading-relaxed font-bold">
											{time}
										</span>
									</span>
								) : (
									<div
										className={classNames(
											"animate-invertPing font-bold",
											readyMsg?.class
										)}
									>
										{readyMsg?.label}
									</div>
								)}
							</div>
						</Transition.Child>
					</div>
				</Dialog>
			</Transition>
		</>
	)
}

export default ShowStartStreamCountDown
