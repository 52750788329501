import { useAppContext } from "context"

const useChatEmitter = () => {
	const { programCode, classCode, socketPoints, videoTimeStamp } =
		useAppContext()

	const joinChatSocket = () => {
		socketPoints.chatSocket.emit("join", {
			programCode,
			classCode,
		})
		console.debug('chatSocket.emit("join")', { programCode, classCode })
	}

	const sendMessage = message => {
		socketPoints.chatSocket.emit("message", {
			...message,
			seekTime: videoTimeStamp,
		})
		console.debug("@chatSocket.emit('message'):", {
			...message,
			seekTime: videoTimeStamp,
		})
	}

	const deleteMessage = message => {
		socketPoints.chatSocket.emit("delete", message)
		console.debug("@chatSocket.emit('delete'):", message)
	}

	const banUser = message => {
		socketPoints.chatSocket.emit("ban", message)
		console.debug("@chatSocket.emit('ban'):", message)
	}

	return {
		joinChatSocket,
		sendMessage,
		deleteMessage,
		banUser,
	}
}

export default useChatEmitter
