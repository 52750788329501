import Join from "pages/Join"
import Class from "pages/Class"
import React from "react"
import { Route, Switch } from "react-router-dom"
import Events from "utils/Events"

const App = () => {
	return (
		<>
			<Switch>
				<Route
					exact
					path="/"
					component={Class}
				/>
				<Route
					exact
					path="/join"
					component={Join}
				/>
			</Switch>
			<Events />
		</>
	)
}

export default App
