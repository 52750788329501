import { Tab as HeadlessTab } from "@headlessui/react"
import {
	AnnotationIcon,
	ClipboardListIcon,
	FolderIcon,
	// HandIcon,
	LoginIcon,
	LogoutIcon,
	UserGroupIcon,
} from "@heroicons/react/solid"
import { IconButton, Tooltip } from "@material-ui/core"
import {
	AgendasView,
	ChatView,
	// DoubtsView,
	ParticipantsView,
	PasteBinView,
	// PluginsView,
} from "components"
import { useAppContext } from "context"
import { useState } from "react"
import classNames from "utils/classNames"
import CookieService from "utils/cookie.service"

const TabView = () => {
	const [notificationEnabled, setNotificationEnabled] = useState(
		CookieService.getAll().notification
	)
	const { sideBarOpen, setValue, unread } = useAppContext()
	// const [requestCount, setRequestCount] = useState(0);
	const [tabValue, setTabValue] = useState(0)

	const handleChange = (event, newTabValue) => {
		setTabValue(newTabValue)
		switch (newTabValue) {
			case 1:
				setValue(prev => ({
					...prev,
					unread: { ...unread, chat: 0 },
				}))
				return
			case 2:
				setValue(prev => ({
					...prev,
					unread: { ...unread, resources: 0 },
				}))
				return
			default:
				return
		}
	}

	// useEffect(
	// 	() =>
	// 		setRequestCount(
	// 			participants.filter((p) => p.handRaiseStatus === "raised")
	// 				.length
	// 		),
	// 	[participants]
	// );
	// useEffect(() => {
	// 	CookieService.setNotificationToken(false)

	// }, []);

	return (
		<>
			<div className="flex md:flex-row flex-col-reverse w-full h-full">
				<>
					<HeadlessTab.Group
						selectedIndex={tabValue}
						onChange={val => {
							setValue(state => ({
								...state,
								sideBarOpen: true,
							}))
							handleChange(null, val)
						}}
					>
						<HeadlessTab.Panels
							className={classNames(
								sideBarOpen ? "w-full" : "hidden"
							)}
						>
							<HeadlessTab.Panel className="bg-white rounded-3xl w-full h-full outline-none transition-all duration-200">
								<AgendasView />
							</HeadlessTab.Panel>
							<HeadlessTab.Panel className="bg-white rounded-3xl w-full h-full outline-none transition-all duration-200">
								<ChatView />
							</HeadlessTab.Panel>
							{/* <HeadlessTab.Panel className='bg-white rounded-3xl w-full h-full outline-none transition-all duration-200'>
								<DoubtsView />
							</HeadlessTab.Panel> */}
							<HeadlessTab.Panel className="bg-white rounded-3xl w-full h-full outline-none transition-all duration-200">
								<PasteBinView />
							</HeadlessTab.Panel>
							<HeadlessTab.Panel className="bg-white rounded-3xl w-full h-full outline-none transition-all duration-200">
								<ParticipantsView />
							</HeadlessTab.Panel>
							{/* {roomJoined ? (
								<HeadlessTab.Panel className='bg-white rounded-3xl w-full h-full outline-none transition-all duration-200'>
									<PluginsView />
								</HeadlessTab.Panel>
							) : null} */}
						</HeadlessTab.Panels>
						<HeadlessTab.List className="flex flex-row md:flex-col md:mx-0 mx-4 md:p-2 px-4 py-3 md:pr-2 md:space-y-5 md:space-x-0 space-x-6 bg-gray-50 border-l-[1px] rounded-3xl md:rounded-none">
							<HeadlessTab
								className={({ selected }) =>
									classNames(
										"relative group w-full square text-sm rounded-md flex flex-col justify-center items-center transition-all duration-200",
										selected
											? "bg-gray-900 text-white"
											: "hover:bg-gray-200 bg-white text-black"
									)
								}
							>
								<ClipboardListIcon className="md:w-5 md:h-5 w-7 h-7" />
								<span className="absolute md:right-[60px] md:bottom-[7px] bottom-[-30px] w-max px-2 py-1 rounded-md bg-[#8b8b8b] text-white text-[10px] font-normal invisible group-hover:visible">
									Agenda
								</span>
							</HeadlessTab>
							<HeadlessTab
								className={({ selected }) =>
									classNames(
										"relative group w-full square text-sm rounded-md flex flex-col justify-center items-center transition-all duration-200",
										selected
											? "bg-gray-900 text-white"
											: "hover:bg-gray-200 bg-white text-black"
									)
								}
							>
								<AnnotationIcon className="md:w-5 md:h-5 w-7 h-7" />
								<span className="absolute md:right-[60px] md:bottom-[7px] bottom-[-30px] w-max px-2 py-1 rounded-md bg-[#8b8b8b] text-white text-[10px] font-normal invisible group-hover:visible">
									Chat
								</span>
								{unread.chat > 0 ? (
									<div className="h-3 w-3 bg-red-600 rounded-full absolute top-[-1px] right-0"></div>
								) : null}
							</HeadlessTab>
							{/* <HeadlessTab
								className={({ selected }) =>
									classNames(
										"relative group w-full square text-sm rounded-md flex flex-col justify-center items-center transition-all duration-200",
										selected
											? "bg-gray-900 text-white"
											: "hover:bg-gray-200 bg-white text-black"
									)
								}
							>
								<HandIcon className='md:w-5 md:h-5 w-7 h-7' />
								<span className='absolute md:right-[60px] md:bottom-[7px] bottom-[-30px] w-max px-2 py-1 rounded-md bg-[#8b8b8b] text-white text-[10px] font-normal invisible group-hover:visible'>
									Hand Raise
								</span>
								<span
									className={classNames(
										"absolute bottom-0 right-0 md:translate-y-1/3 translate-y-1/2 px-1 rounded-md w-max bg-gray-900 text-white text-[10px] font-normal",
										requestCount ? "visible" : "invisible"
									)}
								>
									<span>{requestCount}</span>
								</span>
							</HeadlessTab> */}
							<HeadlessTab
								className={({ selected }) =>
									classNames(
										"relative group w-full square text-sm rounded-md flex flex-col justify-center items-center transition-all duration-200",
										selected
											? "bg-gray-900 text-white"
											: "hover:bg-gray-200 bg-white text-black"
									)
								}
							>
								<FolderIcon className="md:w-5 md:h-5 w-7 h-7" />
								<span className="absolute md:right-[60px] md:bottom-[7px] bottom-[-30px] w-max px-2 py-1 rounded-md bg-[#8b8b8b] text-white text-[10px] font-normal invisible group-hover:visible">
									PasteBin
								</span>
								{unread.resources > 0 ? (
									<div className="h-3 w-3 bg-red-600 rounded-full absolute top-[-1px] right-0"></div>
								) : null}
							</HeadlessTab>
							<HeadlessTab
								className={({ selected }) =>
									classNames(
										"relative group w-full square text-sm rounded-md flex flex-col justify-center items-center transition-all duration-200",
										selected
											? "bg-gray-900 text-white"
											: "hover:bg-gray-200 bg-white text-black"
									)
								}
							>
								<UserGroupIcon className="md:w-5 md:h-5 w-7 h-7" />
								<span className="absolute md:right-[60px] md:bottom-[7px] bottom-[-30px] w-max px-2 py-1 rounded-md bg-[#8b8b8b] text-white text-[10px] font-normal invisible group-hover:visible">
									Participants
								</span>
							</HeadlessTab>
							{/* {roomJoined ? (
								<HeadlessTab
									className={({ selected }) =>
										classNames(
											"relative group w-full square text-sm rounded-md flex flex-col justify-center items-center transition-all duration-200",
											selected
												? "bg-gray-900 text-white"
												: "hover:bg-gray-200 bg-white text-black"
										)
									}
								>
									<svg
										xmlns='http://www.w3.org/2000/svg'
										viewBox='0 0 24 24'
										fill='currentColor'
										className='md:w-5 md:h-5 w-7 h-7'
									>
										<path
											fillRule='evenodd'
											d='M9.315 7.584C12.195 3.883 16.695 1.5 21.75 1.5a.75.75 0 01.75.75c0 5.056-2.383 9.555-6.084 12.436A6.75 6.75 0 019.75 22.5a.75.75 0 01-.75-.75v-4.131A15.838 15.838 0 016.382 15H2.25a.75.75 0 01-.75-.75 6.75 6.75 0 017.815-6.666zM15 6.75a2.25 2.25 0 100 4.5 2.25 2.25 0 000-4.5z'
											clipRule='evenodd'
										/>
										<path d='M5.26 17.242a.75.75 0 10-.897-1.203 5.243 5.243 0 00-2.05 5.022.75.75 0 00.625.627 5.243 5.243 0 005.022-2.051.75.75 0 10-1.202-.897 3.744 3.744 0 01-3.008 1.51c0-1.23.592-2.323 1.51-3.008z' />
									</svg>
									<span className='absolute md:right-[60px] md:bottom-[7px] bottom-[-30px] w-max px-2 py-1 rounded-md bg-[#8b8b8b] text-white text-[10px] font-normal invisible group-hover:visible'>
										Plugins
									</span>
								</HeadlessTab>
							) : null} */}
							<div className="flex-grow flex-col md:space-y-5 justify-end items-end md:flex hidden">
								<Tooltip
									placement="bottom"
									title={
										notificationEnabled
											? "Disable Notifications"
											: "Enable Notifications"
									}
								>
									<IconButton
										onClick={() => {
											CookieService.toggleNotificationToken()
											setTimeout(() =>
												setNotificationEnabled(
													CookieService.getAll()
														.notification,
													500
												)
											)
										}}
									>
										{notificationEnabled ? (
											<svg
												xmlns="http://www.w3.org/2000/svg"
												viewBox="0 0 24 24"
												fill="currentColor"
												className="w-6 h-6 text-black"
											>
												<path d="M5.85 3.5a.75.75 0 00-1.117-1 9.719 9.719 0 00-2.348 4.876.75.75 0 001.479.248A8.219 8.219 0 015.85 3.5zM19.267 2.5a.75.75 0 10-1.118 1 8.22 8.22 0 011.987 4.124.75.75 0 001.48-.248A9.72 9.72 0 0019.266 2.5z" />
												<path
													fillRule="evenodd"
													d="M12 2.25A6.75 6.75 0 005.25 9v.75a8.217 8.217 0 01-2.119 5.52.75.75 0 00.298 1.206c1.544.57 3.16.99 4.831 1.243a3.75 3.75 0 107.48 0 24.583 24.583 0 004.83-1.244.75.75 0 00.298-1.205 8.217 8.217 0 01-2.118-5.52V9A6.75 6.75 0 0012 2.25zM9.75 18c0-.034 0-.067.002-.1a25.05 25.05 0 004.496 0l.002.1a2.25 2.25 0 11-4.5 0z"
													clipRule="evenodd"
												/>
											</svg>
										) : (
											<svg
												xmlns="http://www.w3.org/2000/svg"
												viewBox="0 0 24 24"
												fill="currentColor"
												className="w-6 h-6 text-black"
											>
												<path d="M3.53 2.47a.75.75 0 00-1.06 1.06l18 18a.75.75 0 101.06-1.06l-18-18zM20.57 16.476c-.223.082-.448.161-.674.238L7.319 4.137A6.75 6.75 0 0118.75 9v.75c0 2.123.8 4.057 2.118 5.52a.75.75 0 01-.297 1.206z" />
												<path
													fillRule="evenodd"
													d="M5.25 9c0-.184.007-.366.022-.546l10.384 10.384a3.751 3.751 0 01-7.396-1.119 24.585 24.585 0 01-4.831-1.244.75.75 0 01-.298-1.205A8.217 8.217 0 005.25 9.75V9zm4.502 8.9a2.25 2.25 0 104.496 0 25.057 25.057 0 01-4.496 0z"
													clipRule="evenodd"
												/>
											</svg>
										)}
									</IconButton>
								</Tooltip>
								<Tooltip
									placement="bottom"
									title={
										sideBarOpen
											? "Close Sidebar"
											: "Open Sidebar"
									}
								>
									<IconButton
										className="sideBarControl"
										onClick={() =>
											setValue(state => ({
												...state,
												sideBarOpen: !sideBarOpen,
											}))
										}
									>
										{sideBarOpen ? (
											<LogoutIcon className="w-5 h-5" />
										) : (
											<LoginIcon className="w-5 h-5" />
										)}
									</IconButton>
								</Tooltip>
							</div>
						</HeadlessTab.List>
					</HeadlessTab.Group>
				</>
			</div>
		</>
	)
}

export default TabView
