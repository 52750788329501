import { DyteSettings } from "@dytesdk/react-ui-kit"
import { useDyteMeeting } from "@dytesdk/react-web-core"
import { Dialog, Transition } from "@headlessui/react"
import { Fragment, useEffect } from "react"
import useBreakpoints from "utils/useBreakPoints"

const ShowMeetingSettings = ({
	meetingStates,
	setMeetingStates,
	closeActiveSettings,
}) => {
	const { isMD } = useBreakpoints()
	const { meeting } = useDyteMeeting()

	useEffect(() => {
		const dialog = document.querySelector(".dialog")
		dialog.addEventListener("click", e => {
			if (!e.target.tagName.includes("DYTE")) closeActiveSettings()
		})
		return () => {
			dialog.removeEventListener("click", e => {
				if (!e.target.tagName.includes("DYTE")) closeActiveSettings()
			})
		}
	}, [closeActiveSettings])

	return (
		<>
			<Transition
				appear
				show={meetingStates.activeSettings}
				as={Fragment}
			>
				<Dialog
					as="div"
					className="fixed inset-0 z-10 overflow-y-auto dialog"
					onClose={() => false}
				>
					<div className="min-h-screen px-4 text-center">
						<Transition.Child
							as={Fragment}
							enter="ease-out duration-300"
							enterFrom="opacity-0"
							enterTo="opacity-100"
							leave="ease-in duration-200"
							leaveFrom="opacity-100"
							leaveTo="opacity-0"
						>
							<Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-50" />
						</Transition.Child>

						{/* This element is to trick the browser into centering the modal contents. */}
						<span
							className="inline-block h-screen align-middle"
							aria-hidden="true"
						>
							&#8203;
						</span>
						<Transition.Child
							as={Fragment}
							enter="ease-out duration-300"
							enterFrom="opacity-0 scale-95"
							enterTo="opacity-100 scale-100"
							leave="ease-in duration-200"
							leaveFrom="opacity-100 scale-100"
							leaveTo="opacity-0 scale-95"
						>
							<div className="inline-block md:w-[56rem] w-full md:h-[75vh] my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl settingModal">
								<DyteSettings
									className="w-full h-full"
									size={isMD ? "" : "sm"}
									states={meetingStates}
									onDyteStateUpdate={({ detail }) => {
										if (detail.hasOwnProperty("prefs")) {
											setMeetingStates(state => ({
												...state,
												prefs: {
													...state.prefs,
													...detail.prefs,
												},
											}))
										} else {
											setMeetingStates(state => ({
												...state,
												activeSettings:
													detail.activeSettings,
											}))
										}
									}}
									meeting={meeting}
								/>
							</div>
						</Transition.Child>
					</div>
				</Dialog>
			</Transition>
		</>
	)
}

export default ShowMeetingSettings
