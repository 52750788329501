import "@vime/core/themes/default.css"
import { DefaultUi, Hls, Player, Video, Vimeo, Youtube } from "@vime/react"
import { useAppContext } from "context"
import { useEffect, useRef, useState } from "react"
import { useMeetingEmitter } from "utils/Emits"
import classNames from "utils/classNames"

const VimeJS = () => {
	const {
		trainer,
		fallBackLink,
		isLive,
		videoDetails,
		hasEnded,
		meetingRoomId,
		classCode,
		setValue,
	} = useAppContext()
	const [initialState, setInitialState] = useState(true)
	const player = useRef(null)
	const videoProvider = videoDetails?.provider
	const vmPlayer = useRef()
	const vmPlayerHeight = useRef()
	const { action } = useMeetingEmitter()

	const resizePlayer = () => {
		const shadowPlayer = vmPlayer.current.shadowRoot.children[0]
		shadowPlayer.style.paddingBottom = `${vmPlayerHeight.current}px`

		// if (videoProvider === "vimeo") {
		// 	console.debug("VimeJS resize");
		// 	const playerProvider = document.querySelector(`vm-${videoProvider}`);
		// 	const embed = playerProvider.shadowRoot.children[0];

		// 	if (isMD) {
		// 		embed.style.paddingBottom = `${
		// 			vmPlayerHeight.current * 2
		// 		}px`;
		// 		embed.style.transform = "translateY(-25%)";
		// 	} else {
		// 		embed.style.paddingBottom = "100%";
		// 		embed.style.transform = "translateY(-20%)";
		// 	}
		// }
	}

	const onPlaybackReady = () => {
		const vimePlayer = document.querySelector(".vimePlayer")

		resizePlayer()
		new ResizeObserver(resizePlayer).observe(vimePlayer)
		const videoControls =
			vmPlayer.current.shadowRoot.querySelector(".player.video")
		if (videoControls.childNodes[0].tagName === "DIV")
			videoControls.removeChild(videoControls.childNodes[0])
	}

	const onTimeUpdate = ({ detail: currentTime }) =>
		setValue(state => ({
			...state,
			videoTimeStamp: currentTime,
		}))

	const onPlayChange = ({ detail: playing }) => {
		if (initialState) setInitialState(false)
		action({
			metaData: {},
			roomId: meetingRoomId || classCode,
			action: playing ? "playVideo" : "pauseVideo",
		})

		setValue(state => ({
			...state,
			videoIsPlaying: playing,
		}))
	}

	useEffect(() => {
		vmPlayer.current = document.querySelector("vm-player")
		vmPlayerHeight.current = vmPlayer.current.clientHeight
	}, [])

	return (
		<div className="md:px-4 md:pb-2 md:rounded-md md:flex-grow flex justify-center items-center md:h-[80%] h-full">
			<div className="w-full h-full relative bg-black md:rounded-md vimePlayer">
				<>
					<Player
						class="flex-1 h-full flex items-center"
						ref={player}
						controls={videoProvider === "vimeo" ? true : false}
						onVmPlaybackReady={onPlaybackReady}
						onVmCurrentTimeChange={onTimeUpdate}
						onVmPlayingChange={onPlayChange}
					>
						{videoDetails?.link ? (
							<>
								<Hls
									version="latest"
									config={{}}
								>
									<source
										data-src={videoDetails?.link}
										type="application/x-mpegURL"
									/>
								</Hls>
							</>
						) : videoProvider ? (
							<>
								{videoProvider === "vimeo" ? (
									<Vimeo videoId={videoDetails?.embedId} />
								) : videoProvider === "youtube" ? (
									<Youtube videoId={videoDetails?.embedId} />
								) : (
									<Video>
										<source
											data-src={fallBackLink}
											type="video/mp4"
										/>
									</Video>
								)}
							</>
						) : (
							<Video>
								<source
									data-src={fallBackLink}
									type="video/mp4"
								/>
							</Video>
						)}
						{videoProvider === "vimeo" ? null : <DefaultUi />}
					</Player>
				</>
				<span className="hidden md:flex absolute top-2 left-2 px-2 py-1 z-50 rounded-md md:text-sm text-xs bg-white text-black space-x-2">
					<img
						src={
							trainer.profileImageUrl
								? trainer.profileImageUrl
								: `https://avatars.dicebear.com/api/initials/${trainer.name}.svg`
						}
						alt={trainer.name}
						className="h-5 rounded-md square"
					/>
					<span>{trainer.name}</span>
				</span>
				{initialState ? (
					<span className="hidden md:flex absolute top-2 left-1/2 px-2 py-1 z-50 rounded-md md:text-xl -translate-x-1/2 text-xs text-white space-x-2">
						<span>Press Anywhere To Play</span>
					</span>
				) : null}
				<span
					className={classNames(
						"absolute top-2 right-2 px-3 py-1 z-50 rounded-md md:text-sm text-xs",
						isLive
							? "!px-4 bg-red-500 text-white"
							: hasEnded
							? "bg-yellow-500 text-black"
							: "bg-blue-500 text-white"
					)}
				>
					{isLive ? "Live" : hasEnded ? "Recorded" : "Upcoming"}
				</span>
			</div>
		</div>
	)
}

export default VimeJS
