import axios from "axios"
// import { NextResponse } from "next/server";
import CookieService from "./cookie.service"
import { closeClass } from "./closeClass"

const axiosInstance = axios.create({
	baseURL: "https://service.letsupgrade.in",
	headers: {
		"Content-Type": "application/json",
	},
})

axiosInstance.interceptors.request.use(
	config => {
		const token = CookieService.getLocalAccessToken()

		if (token) {
			config.headers["Authorization"] = "Bearer " + token // for Spring Boot back-end
			// config.headers["x-access-token"] = token; // for Node.js Express back-end
		}
		return config
	},
	error => {
		return Promise.reject(error)
	}
)

axiosInstance.interceptors.response.use(
	res => {
		return res
	},
	async err => {
		const originalConfig = err.config

		if (err.response?.status !== 401) {
			return Promise.reject(err)
		}
		if (originalConfig.url === "/v2/auth/access") {
			// Refresh Token was expired
			closeClass("tokenExpired")
			return Promise.reject(err)
		}

		return await axiosInstance
			.post("/v2/auth/access", {
				refresh: CookieService.getLocalRefreshToken(),
			})
			.then(resp => {
				const { token } = resp.data
				CookieService.updateLocalAccessToken(token)

				return axiosInstance(originalConfig)
			})
			.catch(_error => {
				return Promise.reject(_error)
			})

		// return Promise.reject(err);
	}
)

export default axiosInstance
