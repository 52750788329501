import { useAppContext } from "context"

const useMeetingEmitter = () => {
	const { programCode, classCode, socketPoints } = useAppContext()

	const joinMeetingSocket = () => {
		socketPoints.meetingSocket.emit("join", {
			programCode,
			classCode,
		})
		console.debug('meetingSocket.emit("join")', { programCode, classCode })
	}

	const action = data => {
		socketPoints.meetingSocket.emit("action", data)
		console.debug("@meetingSocket.emit('action'):", data)
	}

	const accept = data => {
		socketPoints.meetingSocket.emit("accept", data)
		console.debug("@meetingSocket.emit('accept'):", data)
	}

	const addToClass = data => {
		socketPoints.meetingSocket.emit("addToClass", data)
		console.debug("@meetingSocket.emit('addToClass'):", data)
	}

	const reject = data => {
		socketPoints.meetingSocket.emit("reject", data)
		console.debug("@meetingSocket.emit('reject'):", data)
	}

	const kick = data => {
		socketPoints.meetingSocket.emit("kick", data)
		console.debug("@meetingSocket.emit('kick'):", data)
	}

	const remove = data => {
		socketPoints.meetingSocket.emit("remove", data)
		console.debug("@meetingSocket.emit('remove'):", data)
	}

	const micToggle = data => {
		socketPoints.meetingSocket.emit("mic", data)
		console.debug("@meetingSocket.emit('mic'):", data)
	}

	const cameraToggle = data => {
		socketPoints.meetingSocket.emit("camera", data)
		console.debug("@meetingSocket.emit('camera'):", data)
	}

	const joinedMeeting = data => {
		socketPoints.meetingSocket.emit("joinedMeeting", data)
		console.debug("@meetingSocket.emit('joinedMeeting'):", data)
	}

	const autoAdmitState = data => {
		socketPoints.meetingSocket.emit("autoAdmitState", data)
		console.debug("@meetingSocket.emit('autoAdmitState'):", data)
	}

	const answer = data => {
		socketPoints.meetingSocket.emit("answer", data)
		console.debug("@meetingSocket.emit('answer'):", data)
	}

	const golive = data => {
		socketPoints.meetingSocket.emit("live", data)
		console.debug("@meetingSocket.emit('live'):", data)
	}

	const endMeeting = data => {
		socketPoints.meetingSocket.emit("endMeeting", data)
		console.debug("@meetingSocket.emit('endMeeting'):", data)
	}

	return {
		joinMeetingSocket,
		action,
		accept,
		addToClass,
		reject,
		kick,
		remove,
		micToggle,
		cameraToggle,
		joinedMeeting,
		answer,
		autoAdmitState,
		golive,
		endMeeting,
	}
}

export default useMeetingEmitter
