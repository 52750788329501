import { PaperAirplaneIcon } from "@heroicons/react/outline"
import { ArrowSmDownIcon, XIcon } from "@heroicons/react/solid"
import { Popover, Typography } from "@material-ui/core"
import EmojiEmotionsOutlinedIcon from "@material-ui/icons/EmojiEmotionsOutlined"
import { useAppContext } from "context"
import { Picker } from "emoji-mart"
import React, { useEffect, useRef, useState } from "react"
import classNames from "utils/classNames"
// import getHex from "utils/getHex";
import { isInViewport, scrollIntoView } from "utils/viewPort"

const Input = ({ handleSendMessage, msgToReplyTo, cancelReply }) => {
	const { unread, setValue } = useAppContext()
	const [msg, setMsg] = useState("")
	const [emojiOpen, setEmojiOpen] = useState(false)
	const msgRef = useRef()
	const inputRef = useRef()

	const sendMessage = e => {
		e?.preventDefault()
		if (msg) {
			handleSendMessage(msg)
		}
		msgRef.current.style.height = "inherit"
		setMsg("")
	}

	const handlePress = e => {
		e.target.style.height = "inherit"

		// Get the computed styles for the element
		var computed = window.getComputedStyle(e.target)

		// Calculate the height
		var height =
			parseInt(computed.getPropertyValue("border-top-width"), 10) +
			e.target.scrollHeight +
			parseInt(computed.getPropertyValue("border-bottom-width"), 10)

		if (height < 100) e.target.style.height = height + "px"
		else e.target.style.height = "100px"
		if (e.key === "Enter" && !e.shiftKey) {
			e.preventDefault()
			sendMessage()
		}
	}

	useEffect(() => {
		if (msgToReplyTo !== null && typeof msgToReplyTo === "object")
			msgRef.current.focus()
	}, [msgToReplyTo])

	return (
		<>
			{unread.chat && !isInViewport("#chatEnd") ? (
				<div className="py-2 bg-transparent flex items-center justify-center">
					<span
						className="max-w-full rounded-md py-1 px-3 flex text-xs items-center font-medium bg-gray-900 text-white"
						onClick={() =>
							scrollIntoView("#chatContainer", "chat", setValue)
						}
					>
						<span>
							{unread.chat} New Message
							{unread.chat > 1 ? "s" : ""}
						</span>
						<ArrowSmDownIcon className="w-4 h-4" />
					</span>
				</div>
			) : null}
			{msgToReplyTo && (
				<div className="flex flex-col px-4 py-4 sm:px-6 border-t-[1px] border-gray-100 bg-white">
					<div
						className={classNames(
							"border-l-[4px] group-scoped cursor-pointer  p-2 rounded-md flex items-center bg-gray-100"
						)}
						// style={{ borderColor: getHex(msgToReplyTo.user?.uid) }}
					>
						<div className="flex-1">
							<div className="flex justify-between items-center">
								<Typography
									variant="subtitle2"
									className="select-text"
									// style={{
									// 	color: getHex(msgToReplyTo.user?.uid),
									// }}
								>
									{msgToReplyTo.user?.name}
								</Typography>
							</div>
							<Typography
								variant="subtitle2"
								className="break-words text-truncate"
							>
								{msgToReplyTo.message}
							</Typography>
						</div>
						<button
							type="button"
							className="inline-flex justify-center p-2 text-sm font-medium text-black hover:bg-gray-200 rounded-full"
							onClick={cancelReply}
						>
							<XIcon className="h-4 w-4" />
						</button>
					</div>
				</div>
			)}
			<div className="px-1 py-3 border-t-[1px] bg-white">
				<div className="relative flex">
					<form
						onSubmit={sendMessage}
						className="w-full"
						ref={inputRef}
					>
						<span
							onClick={() => setEmojiOpen(true)}
							className="absolute inset-y-0 left-0 inline-flex items-center px-2 text-sm text-gray-500"
						>
							<span className="inline-flex items-center hover:scale-125 transition-all duration-500">
								<EmojiEmotionsOutlinedIcon />
							</span>
						</span>
						<textarea
							type="text"
							placeholder="Write message..."
							ref={msgRef}
							value={msg}
							onChange={e => setMsg(e.target.value)}
							// onKeyPress={handlePress}
							onKeyUp={handlePress}
							onKeyDown={handlePress}
							rows={1}
							className="flex-1 block w-full px-10 py-2 sm:text-sm focus:outline-none scrollbar-hide ring-0 border-transparent focus:ring-0 focus:border-transparent"
						/>
						<button
							type="submit"
							className="absolute inset-y-0 right-0 inline-flex items-center px-2 text-sm text-gray-500"
						>
							<PaperAirplaneIcon className="w-6 h-6 rotate-90" />
						</button>
					</form>
				</div>
			</div>
			<Popover
				open={emojiOpen}
				onClose={() => {
					setEmojiOpen(false)
				}}
				anchorEl={inputRef.current}
				anchorOrigin={{
					vertical: "top",
					horizontal: "left",
				}}
				transformOrigin={{
					vertical: "bottom",
					horizontal: "left",
				}}
			>
				<Picker
					emojiSize={28}
					set={"google"}
					showPreview={false}
					showSkinTones={false}
					enableFrequentEmojiSort={true}
					emojiTooltip={true}
					color={"#ff6600"}
					theme={"light"}
					style={{
						height: "357px",
					}}
					onSelect={e => {
						setMsg(s => `${s}${e.native}`)
					}}
				/>
			</Popover>
		</>
	)
}

export default Input
