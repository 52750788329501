import { Dialog, Transition } from "@headlessui/react"
import { XIcon } from "@heroicons/react/outline"
import { useAppContext } from "context"
import { Fragment } from "react"

const ShowDetails = ({ showDetails, closeDetails }) => {
	const { classTitle, classDesc, trainer } = useAppContext()

	return (
		<>
			<Transition
				show={showDetails}
				as={Fragment}
			>
				<Dialog
					as="div"
					className="fixed inset-0 overflow-hidden"
					onClose={closeDetails}
				>
					<div className="absolute inset-0 overflow-hidden">
						<Transition.Child
							as={Fragment}
							enter="ease-out duration-300"
							enterFrom="opacity-0"
							enterTo="opacity-100"
							leave="ease-in duration-200"
							leaveFrom="opacity-100"
							leaveTo="opacity-0"
						>
							<Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-50" />
						</Transition.Child>

						<div className="pointer-events-none fixed inset-x-0 bottom-0 flex w-screen">
							<Transition.Child
								as={Fragment}
								enter="transform transition ease-in-out duration-500 sm:duration-700"
								enterFrom="translate-y-full"
								enterTo="translate-y-0"
								leave="transform transition ease-in-out duration-500 sm:duration-700"
								leaveFrom="translate-y-0"
								leaveTo="translate-y-full"
							>
								<div className="pointer-events-auto w-screen">
									<div className="inline-flex flex-col p-6 space-y-3 overflow-hidden text-left align-middle bg-white shadow-xl rounded-t-lg w-screen">
										<div className="flex items-start justify-between">
											<Dialog.Title
												as="h3"
												className="text-base font-medium leading-6 text-gray-900"
											>
												{classTitle}
											</Dialog.Title>
											<div className="ml-3 flex h-7 items-center">
												<button
													type="button"
													className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-0"
													onClick={closeDetails}
												>
													<span className="sr-only">
														Close panel
													</span>
													<XIcon
														className="h-6 w-6"
														aria-hidden="true"
													/>
												</button>
											</div>
										</div>
										<div className="max-h-[75vh] flex-1 space-y-6">
											{classDesc ? (
												<div className="text-base text-gray-500">
													{classDesc}
												</div>
											) : null}
											<div className="flex items-center space-x-4">
												<img
													src={
														trainer?.profileImageUrl ||
														`https://avatars.dicebear.com/api/initials/${trainer?.name}.svg`
													}
													alt={trainer?.name}
													className="h-10 rounded-xl square"
												/>
												<div className="flex-1 flex items-center">
													<div className="flex-1">
														<div className="text-sm font-medium text-gray-900">
															{trainer?.name}
														</div>
														<div className="text-sm text-gray-500">
															@
															{trainer?.username ||
																"username"}
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</Transition.Child>
						</div>
					</div>
				</Dialog>
			</Transition>
		</>
	)
}

export default ShowDetails
