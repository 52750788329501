import { useAppContext } from "context"

const useBoringMeterEmitter = () => {
	const { programCode, classCode, socketPoints } = useAppContext()

	const joinBoringMeterSocket = () => {
		socketPoints.boringMeterSocket.emit("join", {
			programCode,
			classCode,
		})
		console.debug('boringMeterSocket.emit("join")', {
			programCode,
			classCode,
		})
	}

	return { joinBoringMeterSocket }
}

export default useBoringMeterEmitter
