import { Dialog, Transition } from "@headlessui/react"
import { useAppContext } from "context"
import { Fragment, useState } from "react"
import classNames from "utils/classNames"

const ShowConfirmStreamState = ({
	showConfirmStream,
	onConfirm,
	closeConfirmStream,
}) => {
	const { isLive } = useAppContext()
	const confirmText = isLive ? "Stop" : "Start"
	const [confirmInput, setConfirmInput] = useState("")

	const close = () => {
		setConfirmInput("")
		closeConfirmStream()
	}

	return (
		<>
			<Transition
				appear
				show={showConfirmStream}
				as={Fragment}
			>
				<Dialog
					as="div"
					className="fixed inset-0 z-10 overflow-y-auto"
					onClose={close}
				>
					<div className="min-h-screen px-4 text-center">
						<Transition.Child
							as={Fragment}
							enter="ease-out duration-300"
							enterFrom="opacity-0"
							enterTo="opacity-100"
							leave="ease-in duration-200"
							leaveFrom="opacity-100"
							leaveTo="opacity-0"
						>
							<Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-50" />
						</Transition.Child>

						{/* This element is to trick the browser into centering the modal contents. */}
						<span
							className="inline-block h-screen align-middle"
							aria-hidden="true"
						>
							&#8203;
						</span>
						<Transition.Child
							as={Fragment}
							enter="ease-out duration-300"
							enterFrom="opacity-0 scale-95"
							enterTo="opacity-100 scale-100"
							leave="ease-in duration-200"
							leaveFrom="opacity-100 scale-100"
							leaveTo="opacity-0 scale-95"
						>
							<div className="inline-block w-full max-w-md p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
								<Dialog.Title
									as="h3"
									className="text-xl font-medium leading-6 text-gray-900"
								>
									{confirmText} LiveStream?
								</Dialog.Title>
								<div className="mt-2">
									<p className="text-md text-gray-500">
										Please type{" "}
										<code className="font-extrabold select-none">
											"{confirmText.toUpperCase()}"
										</code>{" "}
										to confirm.
									</p>
									<input
										type="text"
										className="w-full p-2 sm:text-sm border focus:outline-none rounded-md ring-0 focus:ring-0"
										value={confirmInput}
										onChange={e =>
											setConfirmInput(e.target.value)
										}
									/>
								</div>

								<div className="mt-8 flex justify-between">
									<button
										type="submit"
										className="inline-flex justify-center px-4 py-2 text-sm font-medium text-red-900 bg-red-100 border border-transparent rounded-md hover:bg-red-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-red-500"
										onClick={close}
									>
										Cancel
									</button>
									<button
										type="submit"
										className={classNames(
											"inline-flex justify-center px-4 py-2 text-sm font-medium border border-transparent rounded-md focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500",
											confirmInput ===
												confirmText.toUpperCase()
												? "hover:bg-blue-900 bg-blue-500 text-white"
												: "bg-blue-50 text-blue-200"
										)}
										disabled={
											confirmInput !==
											confirmText.toUpperCase()
										}
										onClick={() => {
											onConfirm()
											closeConfirmStream()
											setConfirmInput()
										}}
									>
										I understand,{" "}
										{isLive ? "Stop" : "Start"} Live.
									</button>
								</div>
							</div>
						</Transition.Child>
					</div>
				</Dialog>
			</Transition>
		</>
	)
}

export default ShowConfirmStreamState
