import { useDyteMeeting, useDyteSelector } from "@dytesdk/react-web-core"
import { Dialog, Transition } from "@headlessui/react"
import { IconButton, Tooltip } from "@material-ui/core"
import ArrowBackIcon from "@material-ui/icons/ArrowBack"
import { useAppContext } from "context"
import { Fragment, useEffect, useState } from "react"
import { useMeetingEmitter } from "utils/Emits"
import axios from "utils/axios"
import { closeClass } from "utils/closeClass"

const BackBtn = ({ className }) => {
	const {
		classCode,
		meetingRoomId,
		isLive,
		streamLoc,
		videoDetails,
		setValue,
	} = useAppContext()
	const { action, golive } = useMeetingEmitter()
	let [isOpen, setIsOpen] = useState(false)
	const { meeting } = useDyteMeeting()
	const joined = useDyteSelector(m => m.meta.joined)
	const participants = useDyteSelector(m => m.participants)
	const [ytStreamStatus, setYtStreamStatus] = useState(null)

	function closeModal() {
		setIsOpen(false)
	}

	function openModal() {
		setIsOpen(true)
	}

	const handleBack = () => {
		if (joined) {
			openModal()
		} else {
			leaveMeeting()
		}
	}

	const updateClassStatus = () => {
		axios.get(`/v2/lms/classes/status/${classCode}`).then(resp => {
			setValue(state => ({
				...state,
				...resp.data.results.data,
				isPast: resp.data.results.data.hasEnded,
			}))
		})
	}

	const leaveMeeting = () => {
		if (joined) {
			meeting.leaveRoom()
			action({
				metaData: {},
				roomId: meetingRoomId || classCode,
				action: "meetingLeft",
			})
			updateClassStatus()
		} else closeClass("closeClass")
	}

	const exitMeeting = () => {
		if (isLive) {
			axios
				.put(`/v2/lms/classes/${classCode}/dyte/meeting/recording/stop`)
				.then(() => {
					if (streamLoc.url.includes("youtube"))
						stopYTLive(videoDetails.embedId)
					else updateLive(false)
				})
				.catch(e => console.error(e))
		} else finishClass()
	}

	const stopYTLive = id => {
		console.debug("@stopYTLive", id)
		setTimeout(() => {
			axios({
				method: "POST",
				url: `/v2/admin/classes/stream/youtube/end/${id}`,
			})
				.then(resp => {
					if (resp.data.results.data.lifeCycleStatus === "complete") {
						golive({
							roomId: meetingRoomId || classCode,
							classCode,
							isLive: false,
						})
						setValue(prev => ({ ...prev, nowLive: false }))
					}
				})
				.catch(e => {
					setYtStreamStatus(e.response.data.results.data.reason)
					console.error(e.response.data.results.data.reason)
				})
		}, 2000)
	}

	const retryYtStream = () => {
		setYtStreamStatus(null)
		console.debug(videoDetails.embedId, "end again")
		stopYTLive(videoDetails.embedId)
	}

	const updateLive = liveStatus => {
		golive({
			roomId: meetingRoomId || classCode,
			classCode,
			isLive: liveStatus,
		})
		updateClassStatus()
		setValue(prev => ({ ...prev, isLive: liveStatus }))
	}

	const finishClass = async () => {
		closeModal()
		if (joined) {
			await participants.kickAll()
			meeting.leaveRoom()
			updateClassStatus()
		} else closeClass("endClass")
	}

	useEffect(() => {
		console.debug(ytStreamStatus)
		if (ytStreamStatus === null) return
		switch (ytStreamStatus) {
			case "errorStreamInactive":
				console.error("errorStreamInactive")
				retryYtStream()
				break
			case "redundantTransition":
				console.error("redundantTransition")
				updateLive(false)
				break
			case "invalidTransition":
				console.error("invalidTransition")
				retryYtStream()
				break
			case "liveBroadcastNotFound":
				console.error("The request is using an unsupported protocol.")
				break
			default:
				console.error("OOPS")
				break
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [ytStreamStatus])

	return (
		<>
			<div className={className}>
				<Tooltip
					placement="bottom"
					title="Go Back"
				>
					<IconButton
						className="text-inherit"
						onClick={handleBack}
					>
						<ArrowBackIcon />
					</IconButton>
				</Tooltip>
			</div>
			<Transition
				appear
				show={isOpen}
				as={Fragment}
			>
				<Dialog
					as="div"
					className="fixed inset-0 z-10 overflow-y-auto"
					onClose={closeModal}
				>
					<div className="min-h-screen px-4 text-center">
						<Transition.Child
							as={Fragment}
							enter="ease-out duration-300"
							enterFrom="opacity-0"
							enterTo="opacity-100"
							leave="ease-in duration-200"
							leaveFrom="opacity-100"
							leaveTo="opacity-0"
						>
							<Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-50" />
						</Transition.Child>

						{/* This element is to trick the browser into centering the modal contents. */}
						<span
							className="inline-block h-screen align-middle"
							aria-hidden="true"
						>
							&#8203;
						</span>
						<Transition.Child
							as={Fragment}
							enter="ease-out duration-300"
							enterFrom="opacity-0 scale-95"
							enterTo="opacity-100 scale-100"
							leave="ease-in duration-200"
							leaveFrom="opacity-100 scale-100"
							leaveTo="opacity-0 scale-95"
						>
							<div className="inline-block w-full max-w-md p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
								<Dialog.Title
									as="h3"
									className="text-xl text-center font-medium leading-6 text-gray-900"
								>
									Are You Sure!
								</Dialog.Title>
								<div className="mt-2">
									<p className="text-md text-center text-gray-500">
										End this class for everyone?
									</p>
								</div>

								<div className="mt-8 flex justify-between">
									<button
										type="submit"
										className="inline-flex justify-center px-4 py-2 text-sm font-medium text-blue-900 bg-blue-100 border border-transparent rounded-md hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500"
										onClick={leaveMeeting}
									>
										Just Leave the Class
									</button>
									<button
										type="submit"
										className="inline-flex justify-center px-4 py-2 text-sm font-medium text-red-900 bg-red-100 border border-transparent rounded-md hover:bg-red-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-red-500"
										onClick={exitMeeting}
									>
										End the Class for everyone
									</button>
								</div>
							</div>
						</Transition.Child>
					</div>
				</Dialog>
			</Transition>
		</>
	)
}

export default BackBtn
