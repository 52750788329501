import io from "socket.io-client"

export const connectSockets = token => {
	const socketURI = process.env.REACT_APP_SOCKET
	// const socketURI = "https://c3f1-123-252-203-188.ngrok.io";
	return {
		meetingSocket: io(`${socketURI}/meeting`, {
			reconnection: true,
			reconnectionDelay: 1000,
			reconnectionAttempts: 5,
			query: {
				token,
			},
		}),

		chatSocket: io(`${socketURI}/chat`, {
			reconnection: true,
			reconnectionDelay: 1000,
			reconnectionAttempts: 5,
			query: {
				token,
			},
		}),

		boringMeterSocket: io(`${socketURI}/boringmeter`, {
			reconnection: true,
			reconnectionDelay: 1000,
			reconnectionAttempts: 5,
			query: {
				token,
			},
		}),

		pasteBinSocket: io(`${socketURI}/paste-bin`, {
			reconnection: true,
			reconnectionDelay: 1000,
			reconnectionAttempts: 5,
			query: {
				token,
			},
		}),

		sessionSocket: io(`${socketURI}/session`, {
			reconnection: true,
			reconnectionDelay: 1000,
			reconnectionAttempts: 5,
			query: {
				token,
			},
		}),
	}
}
