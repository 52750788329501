import Menu from "@material-ui/core/Menu"
import MenuItem from "@material-ui/core/MenuItem"
import { useAppContext } from "context"
import React, { useState } from "react"

const initialState = {
	mouseX: null,
	mouseY: null,
}

const ContextMenu = ({
	children,
	menuOptions,
	msg,
	className,
	showPreview = false,
}) => {
	const [state, setState] = useState(initialState)
	const { participants } = useAppContext()

	const handleClick = e => {
		e.preventDefault()
		setState({
			mouseX: e.clientX - 2,
			mouseY: e.clientY - 4,
		})
	}

	const handleClose = () => {
		setState(initialState)
	}

	return (
		<div
			className={className}
			onContextMenu={e =>
				msg.source !== "input" && showPreview === false
					? handleClick(e)
					: false
			}
		>
			{children}
			<Menu
				keepMounted
				open={state.mouseY !== null}
				onClose={handleClose}
				onClick={handleClose}
				anchorReference="anchorPosition"
				anchorPosition={
					state.mouseY !== null && state.mouseX !== null
						? { top: state.mouseY, left: state.mouseX }
						: undefined
				}
			>
				{menuOptions.map(
					(
						{ isFor, disabled, icon: Component, action, label },
						idx
					) => (
						<MenuItem
							key={idx}
							onClick={e => action({ e, msg })}
							disabled={
								disabled
									? isFor === "user"
										? participants?.find(
												p => p.uid === msg.user.uid
										  )?.permissions?.chat?.banned
										: msg[disabled]
									: false
							}
						>
							{!!Component && (
								<Component className="w-4 h-4 mr-2" />
							)}
							{label}
						</MenuItem>
					)
				)}
			</Menu>
		</div>
	)
}
export default ContextMenu
