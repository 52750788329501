export const isInViewport = function (elem) {
	elem = document.querySelector(elem)
	if (!elem) return false
	const bounding = elem.getBoundingClientRect()
	return (
		bounding.top >= 0 &&
		bounding.left >= 0 &&
		bounding.bottom <=
			(window.innerHeight || document.documentElement.clientHeight) &&
		bounding.right <=
			(window.innerWidth || document.documentElement.clientWidth)
	)
}

export const scrollIntoView = (elem, unreadType, setValue) => {
	if (unreadType)
		setValue(state => ({
			...state,
			unread: { ...state.unread, [unreadType]: 0 },
		}))
	const container = document.querySelector(elem)
	container.scrollTo({
		top: container.scrollHeight,
		behavior: "smooth",
	})
}
